import React, { useState } from 'react'
import { useRouter } from 'solito/router'
import PATHS from '@centrito/common/paths'
import { TrustPaymentModal } from '@centrito/app/features/shopper/home/components/TrustPaymentModal'
import useWindowDimensions from '@centrito/app/utils/hooks/useWindowDimensions'
import { Text, XStack, YStack } from '@centrito/ui/src'
import { CaptionStandardText, ContentBoldText } from '@centrito/ui/src/components/text/new'
import { HomeValueProposalCard } from './Card'

const PADDING_HORIZONTAL = 15
const GAP = 10

export const HomeValueProposalCards: React.FC = () => {
  const { width } = useWindowDimensions()
  const adjustedWidth = width - PADDING_HORIZONTAL * 2 - GAP
  const [isTrustPaymentModalOpen, setIsTrustPaymentModalOpen] = useState(false)
  const router = useRouter()
  return (
    <XStack
      gap={GAP}
      justifyContent="space-between"
      paddingHorizontal={PADDING_HORIZONTAL}
      flex={1}
    >
      <TrustPaymentModal
        isOpen={isTrustPaymentModalOpen}
        onOpenChange={(open: boolean) => {
          setIsTrustPaymentModalOpen(open)
        }}
      />
      <HomeValueProposalCard
        width={adjustedWidth / 2}
        Icon={<Text>🚚</Text>}
        Content={
          <YStack>
            <ContentBoldText color="#06264C">Envío gratis</ContentBoldText>
            <CaptionStandardText color="#06264C">a puntos Samsam</CaptionStandardText>
          </YStack>
        }
        onPress={(): void => router.push(PATHS.AlliedStores.Index)}
      />
      <HomeValueProposalCard
        width={adjustedWidth / 2}
        Icon={<Text>💵</Text>}
        Content={
          <ContentBoldText color="#06264C" maxWidth="90%">
            Pagos contraentrega
          </ContentBoldText>
        }
        onPress={(): void => setIsTrustPaymentModalOpen(true)}
      />
    </XStack>
  )
}
