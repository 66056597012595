import React from 'react'
import { OUTLETS_LOGOS } from '@centrito/ui/src/components/svgs/outlets/logos'
import { DiscountTag, type DiscountTagProps } from './DiscountTag'

interface DiscountTagType {
  Big: React.FC<Pick<DiscountTagProps, 'discount'>>
  Small: React.FC<Pick<DiscountTagProps, 'discount'>>
}

function createDiscountTag(
  logoName: keyof typeof OUTLETS_LOGOS,
  size: 'big' | 'small',
  logoSize: { width: number; height: number },
): React.FC<Pick<DiscountTagProps, 'discount'>> {
  return function DiscountTagComponent({ discount }: Pick<DiscountTagProps, 'discount'>) {
    // Create the logo element using the provided logo name and size
    const logoElement = OUTLETS_LOGOS[logoName](logoSize)
    // Return the DiscountTag with all required props
    return <DiscountTag size={size} logo={logoElement} discount={discount} />
  }
}

export const LOGO_SIZES: Record<
  keyof typeof OUTLETS_LOGOS,
  { big: { width: number; height: number }; small: { width: number; height: number } }
> = {
  ADIDAS: { big: { width: 50, height: 30 }, small: { width: 30, height: 20 } },
  'AMERICAN EAGLE': { big: { width: 28, height: 28 }, small: { width: 20, height: 20 } },
  AMERICANINO: { big: { width: 80, height: 28 }, small: { width: 65, height: 20 } },
  AVEMARÍA: { big: { width: 80, height: 28 }, small: { width: 55, height: 20 } },
  'BABY FRESH': { big: { width: 110, height: 26 }, small: { width: 70, height: 20 } },
  BRISSA: { big: { width: 60, height: 18 }, small: { width: 40, height: 20 } },
  CAROL: { big: { width: 70, height: 28 }, small: { width: 55, height: 20 } },
  "CARTER'S": { big: { width: 60, height: 25 }, small: { width: 50, height: 20 } },
  CHEVIGNON: { big: { width: 80, height: 25 }, small: { width: 65, height: 20 } },
  CROMANTIC: { big: { width: 100, height: 30 }, small: { width: 70, height: 20 } },
  ESPRIT: { big: { width: 80, height: 16 }, small: { width: 50, height: 20 } },
  FLORIPONDIA: { big: { width: 90, height: 18 }, small: { width: 70, height: 20 } },
  'FUERA DE SERIE': { big: { width: 65, height: 28 }, small: { width: 40, height: 20 } },
  GEF: { big: { width: 35, height: 19 }, small: { width: 20, height: 20 } },
  KOAJ: { big: { width: 60, height: 20 }, small: { width: 40, height: 20 } },
  'LILI PINK': { big: { width: 90, height: 18 }, small: { width: 60, height: 20 } },
  MIC: { big: { width: 30, height: 30 }, small: { width: 20, height: 20 } },
  MOVIES: { big: { width: 20, height: 20 }, small: { width: 15, height: 20 } },
  OFFCORSS: { big: { width: 80, height: 20 }, small: { width: 50, height: 20 } },
  'PEPE GANGA': { big: { width: 80, height: 28 }, small: { width: 50, height: 20 } },
  'PUNTO BLANCO': { big: { width: 100, height: 25 }, small: { width: 70, height: 20 } },
  RIFLE: { big: { width: 60, height: 25 }, small: { width: 50, height: 20 } },
  'SEVEN SEVEN': { big: { width: 100, height: 25 }, small: { width: 70, height: 20 } },
  'SPORT FITNESS': { big: { width: 100, height: 28 }, small: { width: 70, height: 20 } },
  TENNIS: { big: { width: 50, height: 20 }, small: { width: 50, height: 20 } },
  THM: { big: { width: 60, height: 25 }, small: { width: 50, height: 20 } },
  TOTTO: { big: { width: 65, height: 21.83 }, small: { width: 40, height: 20 } },
  TOYSMART: { big: { width: 65, height: 28 }, small: { width: 60, height: 20 } },
}

export const DISCOUNT_TAGS: Record<string, DiscountTagType> = Object.fromEntries(
  Object.entries(LOGO_SIZES).map(([key, sizes]) => [
    key,
    {
      Big: createDiscountTag(key as keyof typeof LOGO_SIZES, 'big', sizes.big),
      Small: createDiscountTag(key as keyof typeof LOGO_SIZES, 'small', sizes.small),
    },
  ]),
)
