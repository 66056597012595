import React, { useEffect, useState } from 'react'
import { ArrowUp } from '@tamagui/lucide-icons'
import { NativeEventEmitter } from 'react-native'
import { Button, debounce } from '@centrito/ui/src'

const eventEmitter = new NativeEventEmitter()

const SHOW_BACK_TO_TOP_BUTTON_EVENT = 'SHOW_BACK_TO_TOP_BUTTON'
const MAX_SCROLL_Y_OFFSET = 600

export const showBackToTopButton = (scrollYOffset: number, onPress: () => void): void => {
  if (scrollYOffset > MAX_SCROLL_Y_OFFSET) {
    eventEmitter.emit(SHOW_BACK_TO_TOP_BUTTON_EVENT, { isVisible: true, onPress })
  } else {
    eventEmitter.emit(SHOW_BACK_TO_TOP_BUTTON_EVENT, { isVisible: false })
  }
}

export const hideBackToTopButton = (): void => {
  eventEmitter.emit(SHOW_BACK_TO_TOP_BUTTON_EVENT, { isVisible: false })
}

export const BackToTopButton: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [onPress, setOnPress] = useState<() => void>(() => {})

  useEffect(() => {
    eventEmitter.addListener(
      SHOW_BACK_TO_TOP_BUTTON_EVENT,
      debounce((e: { isVisible: boolean; onPress?: () => void }) => {
        setIsVisible(e.isVisible)
        setOnPress(() => e.onPress)
      }, 500),
    )
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (!isVisible) return null

  return (
    <Button
      circular
      icon={<ArrowUp color="$primary1000" size={20} />}
      onPress={onPress}
      backgroundColor="$backgroundGrey"
      size={42}
      shadowColor="#00000029"
      shadowOffset={{ width: 0, height: 2 }}
      shadowRadius={4}
      alignSelf="flex-end"
      marginRight={10}
      elevation={2}
    />
  )
}
