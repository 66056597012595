import * as React from 'react'
import type { SvgProps } from 'react-native-svg'
import Svg, { Path } from 'react-native-svg'

const SamSamHappy = (props: SvgProps) => (
  <Svg width={66} height={52} fill="none" {...props}>
    <Path
      stroke="#546EE5"
      strokeWidth={1.5}
      d="M1.5 38.448h-.012V21.85C1.488 9.826 11.567.104 23.531.788c1.742.114 3.404.415 4.989.9.215.099.42.148.605.186 1.24.37 2.526.575 3.896.575 1.245 0 2.454-.165 3.623-.497a7.88 7.88 0 0 0 1.042-.332c1.9-.568 3.874-.87 5.964-.87 11.519 0 20.864 9.345 20.864 20.864V39.182c-.268 6.705-5.822 12.068-12.566 12.068a12.603 12.603 0 0 1-8.913-3.692l-.22-.22h-.654l-.218.211a12.497 12.497 0 0 1-8.764 3.543 12.533 12.533 0 0 1-8.913-3.692l-.22-.22h-.672l-.221.233c-2.27 2.381-5.512 3.837-9.06 3.837-6.785 0-12.305-5.37-12.566-12.082l-.028-.72Z"
    />
    <Path
      fill="#4747ED"
      d="M42.787 25.147c-1.068-1.104-1.629-2.313-1.852-3.688-.127-.775.083-1.17.705-1.266.63-.1.937.167 1.103.95.504 2.383 2.046 3.666 4.157 3.465 2.045-.197 3.556-1.48 4.104-3.49.035-.136.087-.268.13-.412.386-.373.833-.517 1.302-.228.495.306.442.828.324 1.3-.364 1.415-1.135 2.572-2.405 3.465.605.28 1.187.302 1.743.42.758.167 1.14.622 1.017 1.205-.11.512-.657.797-1.376.626-1.905-.455-3.845-.652-5.794-.657a24.47 24.47 0 0 0-5.05.5c-.525.109-.963.021-1.222-.478-.289-.556.053-1.17.75-1.314.73-.153 1.484-.25 2.364-.398ZM28.74 25.555c.535.065.859.324 1.025.862.28.92.902 1.424 1.595 1.397.709-.03 1.296-.56 1.58-1.436.325-.999.517-1.113 1.53-.876.288.258.393.596.354.985-.158 1.595-1.594 3.01-3.189 3.145-1.686.14-3.157-.902-3.622-2.558-.197-.687-.105-1.235.727-1.52ZM56.397 26.321c-.364-1.082-.096-1.484.91-1.493 1.245-.013 2.466.17 3.688.359.574.087.81.486.74 1.02-.06.49-.424.75-.902.684-1.278-.175-2.553-.346-3.85-.333-.192-.005-.385-.154-.586-.237ZM60.649 20.407c.013.46-.223.775-.635.893a32.55 32.55 0 0 0-3.044 1.073c-.53.215-.99.18-1.266-.39-.223-.468.035-.941.648-1.222 1.008-.464 2.055-.81 3.11-1.138.635-.193 1.16.175 1.187.784ZM17.723 16.82a4.265 4.265 0 0 0-4.266 4.266v3.105c0 .802.228 1.55.613 2.195.228-.15.517-.241.859-.246 2.137-.013 4.296-.157 6.363.377.438-.67.692-1.467.692-2.326v-3.105a4.259 4.259 0 0 0-4.261-4.266Zm-.202 4.599a1.306 1.306 0 0 1-2.61 0v-1.09a1.306 1.306 0 0 1 2.61 0v1.09Z"
    />
    <Path
      fill="#4747ED"
      d="M13.959 25.525c-.88.145-1.63.246-2.37.399-.691.145-1.037.762-.748 1.314.258.5.696.587 1.222.477a24.47 24.47 0 0 1 5.05-.499c1.948.004 3.884.201 5.794.657.718.17 1.265-.114 1.375-.626.127-.583-.259-1.038-1.016-1.205-.556-.122-1.143-.14-1.743-.42a24.21 24.21 0 0 0-7.564-.097Z"
    />
  </Svg>
)
export default SamSamHappy
