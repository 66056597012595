import { createAsyncThunk } from '@reduxjs/toolkit'
import type { CartPublicComposite } from '@centrito/api/nest/shopper/cart/domain/composites'
import trpcProxyClient from '@centrito/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'
import { getCartOwner } from '@centrito/app/store/slices/userData/utils/getCartOwner'

const validateCart = createAsyncThunk<
  | {
      isValid: true
      newCart: CartPublicComposite
    }
  | {
      isValid: false
      newCart: CartPublicComposite
      cartValidationError: string
    },
  void,
  AppThunkApiConfig
>(`${REDUCER_NAMES.USER_DATA}/validateCart`, async (payload, thunkAPI) => {
  const currentState = thunkAPI.getState()
  const cartOwner = getCartOwner(currentState, thunkAPI.dispatch)
  const validateCartResult = await trpcProxyClient.user.cart.validateCart.mutate({
    cartOwner,
  })

  return validateCartResult
})
export default validateCart
