// In order to use uuidv4 we need to import 'react-native-get-random-values' for it to work on mobile
import 'react-native-get-random-values'
import { $Enums } from '@centrito/db'
import { type UserDataState } from './types'

const initialState: UserDataState = {
  userProfile: null,
  cart: {
    cart: {
      id: '',
      anonymousUserId: null,
      deliveryData: null,
      deliveryDate: null,
      deliveryType: null,
      discountData: null,
      paymentData: null,
      ongoingPaymentRequestId: null,
      validPaymentMethods: {
        methods: [
          $Enums.OrderPaymentMethod.CASH,
          $Enums.OrderPaymentMethod.PALOMMA__PSE,
          $Enums.OrderPaymentMethod.PALOMMA__BANCOLOMBIA,
          $Enums.OrderPaymentMethod.PALOMMA__CARD,
        ],
        reason: null,
      },
    },
    items: [],
    pricingData: {
      priceItems: {
        priceCost: 0,
        priceSubtotal: 0,
        priceDiscountItemPublic: 0,
        priceDiscountItemCoupon: 0,
        priceTotalAfterItemLevelDiscounts: 0,
      },
      priceSubtotal: 0,
      priceDiscountOrderCoupon: {
        campaignId: null,
        amount: 0,
        applicableSubtotal: 0,
      },
      priceShipping: {
        revenue: 0,
        discount: {
          amount: 0,
          campaignId: null,
        },
      },
      priceTotal: 0,
    },
  },
  selectedStore: null,
  checkout: {
    isActionEnabled: true,
    deliveryStepState: {
      selectedType: null,
    },
    paymentStepState: {
      selectedMethod: null,
      selectedBank: null,
      selectedBankName: null,
    },
  },
  searchedTerms: [],
  anonymousCartId: null,
  anonymousUserId: null,
  sessionProductIds: [],
  isEmailNotificationsEnabled: false,
  userCoupons: null,
}

export default initialState
