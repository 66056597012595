import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import type { InvitationPublic } from '@centrito/api/nest/platform/database/domain'
import { seenInvitationsInitialState } from './initialState'
import { type SeenInvitationsStore } from './types'

export const useSeenInvitationsStore = create<SeenInvitationsStore>()(
  devtools(
    persist(
      immer((set, get) => ({
        ...seenInvitationsInitialState,
        resetInvitations: (): void => set(seenInvitationsInitialState),
        markAsSeen: (invitationId: string): void =>
          set(
            (state) => {
              if (!state.seenIds.includes(invitationId)) {
                state.seenIds.push(invitationId)
              }
            },
            undefined,
            'mark-as-seen',
          ),
        hasSeenInvitation: (invitationId: string): boolean => get().seenIds.includes(invitationId),
        getFirstUnseenInvitation: (invitations: InvitationPublic[]): InvitationPublic | null => {
          const unseenInvitation = invitations.find(
            (invitation) => !get().hasSeenInvitation(invitation.id),
          )
          return unseenInvitation ?? null
        },
      })),
      {
        name: 'price-chop-seen-invitations',
        version: 1,
      },
    ),
    {
      name: 'seen-invitations',
    },
  ),
)
