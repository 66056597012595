import { useEffect } from 'react'
import isNil from 'lodash.isnil'
import { useRouter } from 'solito/router'
import PATHS from '@centrito/common/paths'
import { useSeenInvitationsStore } from '@centrito/app/features/shopper/price-chop/contexts'
import { useGetDiscountGame } from '@centrito/app/features/shopper/price-chop/hooks'

export const useCheckUnseenInvitation = (): void => {
  const router = useRouter()
  const { getFirstUnseenInvitation } = useSeenInvitationsStore()

  const { data } = useGetDiscountGame()

  useEffect(() => {
    if (!isNil(data)) {
      const unseenInvitation = getFirstUnseenInvitation(data.discountGame.invitations)

      if (unseenInvitation) {
        router.push(PATHS.PriceChop.Share)
      }
    }
  }, [data, getFirstUnseenInvitation, router])
}
