import React from 'react'
import { ArrowUpDown, CircleUser, Coins, PaintBucket, Ruler, Tag } from '@tamagui/lucide-icons'
import { GetProductsFilterType } from '@centrito/api/shared/enums'
import { Square } from '@centrito/ui/src'
import type { FilterOverlay } from './types'

export const PRICE_RANGE_DEFAULT = { low: 5000, high: 150000 }

export const FILTER_OVERLAY_OPTION_GROUP: {
  [K in GetProductsFilterType]?: FilterOverlay.OptionGroupConfig
} = {
  [GetProductsFilterType.BRAND_ID]: {
    optionGroupName: 'Marcas',
    optionGroupIcon: <CircleUser color="$samsamBlue2" />,
    isExclusive: false,
  },
  [GetProductsFilterType.CATEGORY]: {
    optionGroupName: 'Categorías',
    optionGroupIcon: <Tag color="$samsamBlue2" />,
    isExclusive: false,
  },
  [GetProductsFilterType.COLOR]: {
    optionGroupName: 'Color',
    optionGroupIcon: <PaintBucket color="$samsamBlue2" />,
    isExclusive: false,
  },
  [GetProductsFilterType.SIZE]: {
    optionGroupName: 'Talla',
    optionGroupIcon: <Ruler color="$samsamBlue2" />,
    isExclusive: false,
  },
  [GetProductsFilterType.PRICE_RANGE]: {
    optionGroupName: 'Precio',
    optionGroupIcon: <Coins color="$samsamBlue2" />,
    isExclusive: false,
  },
  [GetProductsFilterType.SUPPLIER]: {
    optionGroupName: 'Proveedor',
    optionGroupIcon: <CircleUser color="$samsamBlue2" />,
    isExclusive: false,
  },
  [GetProductsFilterType.SORT_TYPE]: {
    optionGroupName: 'Ordenar',
    optionGroupIcon: <ArrowUpDown color="$samsamBlue2" />,
    isExclusive: false,
  },
}

export const INITIAL_OPTIONS: FilterOverlay.Options = {
  [GetProductsFilterType.BRAND_ID]: undefined,
  [GetProductsFilterType.CATEGORY]: undefined,
  [GetProductsFilterType.COLOR]: undefined,
  [GetProductsFilterType.SIZE]: undefined,
  [GetProductsFilterType.PRICE_RANGE]: [
    {
      icon: <Square />,
      isChecked: true,
      name: 'Precio',
      _filterItem: { type: GetProductsFilterType.PRICE_RANGE, value: { low: 5000, high: 150000 } },
    },
  ],
  [GetProductsFilterType.SUPPLIER]: undefined,
  [GetProductsFilterType.SORT_TYPE]: undefined,
}
