import React from 'react'
import { AppCrashError, FatalError } from '@centrito/api/nest/platform/sentry/domain/errors'
import Sentry from '@centrito/app/sentry/shopper'
import { getCurrentVersionInfo } from '@centrito/app/utils/ota/getCurrentVersionInfo'
import sentryClient from '@centrito/app/utils/services/sentry'
import { isClient } from '@centrito/ui/src'
import { Fallback } from './Fallback'

interface CustomErrorBoundaryProps {
  fallback?: React.ReactNode
  hasWhatsAppButton?: boolean
  children: React.ReactNode
}

export class CustomErrorBoundary extends React.Component<CustomErrorBoundaryProps> {
  constructor(props: CustomErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true }
  }

  async componentDidCatch(error: Error): Promise<void> {
    const versionInfo = await getCurrentVersionInfo()

    if (isClient) {
      Sentry.captureException(error)
    } else if (!versionInfo.isCurrentVersion) {
      Sentry.captureException(new AppCrashError(error, { isOutdated: true }), {
        tags: {
          ...versionInfo,
        },
      })
    } else {
      sentryClient.captureFatalException(
        new FatalError(error, {
          engineer: 'daniel',
        }),
        versionInfo,
      )
    }
  }

  render(): React.ReactNode {
    const { hasError } = this.state as { hasError: boolean }
    if (isClient) {
      return this.props.children
    } else if (hasError) {
      if (this.props.fallback) {
        return this.props.fallback
      }
      return <Fallback hasWhatsAppButton={this.props.hasWhatsAppButton} />
    }

    return this.props.children
  }
}
