import {
  type Action,
  type SerializedError,
  type ThunkAction,
  type TypedStartListening,
  configureStore,
} from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { wrapMakeStore } from 'next-redux-cookie-wrapper'
import { useDispatch as useDispatchBase, useSelector as useSelectorBase } from 'react-redux'
import { combineReducers } from 'redux'
import { getPersistConfig } from 'redux-deep-persist'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { isServer } from '@centrito/app/config'
import authRehydrateMiddleware from '@centrito/app/store/middleware/authRehydrate'
import fixCartHackMiddleware from '@centrito/app/store/middleware/fixCartHack'
import persistHackMiddleware from '@centrito/app/store/middleware/persistHack'
import { migrateState } from '@centrito/app/store/migrators'
import authSlice from '@centrito/app/store/slices/auth'
import merchantCustomerDataSlice from './slices/merchantCustomerData'
import merchantDataSlice from './slices/merchantData'
import userDataSlice from './slices/userData'

const sentryReduxEnhancer = Sentry.createReduxEnhancer({})

const reducers = {
  [authSlice.name]: authSlice.reducer,
  [userDataSlice.name]: userDataSlice.reducer,
  [merchantDataSlice.name]: merchantDataSlice.reducer,
  [merchantCustomerDataSlice.name]: merchantCustomerDataSlice.reducer,
}

export const makeStore = wrapMakeStore(() => {
  const rootReducer = combineReducers(reducers)
  const reducer = isServer
    ? reducers
    : (persistReducer(
        getPersistConfig({
          key: 'root',
          storage,
          timeout: null as any,
          whitelist: [
            `${authSlice.name}.authenticatedData`,
            userDataSlice.name,
            merchantDataSlice.name,
            merchantCustomerDataSlice.name,
          ],
          rootReducer,
          version: 2,
          migrate: (state) => Promise.resolve(migrateState(state)),
        }),
        rootReducer,
      ) as any)

  const store = configureStore({
    reducer,
    // ORDER MATTERS, middleware must come before enhancers
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [
            ...(isServer ? [] : [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]),
          ],
        },
      })
        .concat(fixCartHackMiddleware.middleware)
        .concat(persistHackMiddleware.middleware)
        .concat(authRehydrateMiddleware.middleware),
    // ORDER MATTERS, enhancers must come after middleware
    enhancers: (getDefaultEnhancers) => {
      return getDefaultEnhancers().concat(sentryReduxEnhancer)
    },
    devTools: true,
  })

  if (!isServer) {
    ;(store as any).__persistor = persistStore(store)
  }

  return store
})

type Store = ReturnType<typeof makeStore>

export type AppDispatch = Store['dispatch']
export type RootState = ReturnType<Store['getState']>

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
export interface AppThunkApiConfig {
  state: RootState
  dispatch: AppDispatch
  rejectValue: SerializedError
}

export type AppStartListening = TypedStartListening<RootState, AppDispatch>

export const useDispatch = () => useDispatchBase<AppDispatch>()

export const useSelector = <TSelected = unknown>(
  selector: (state: RootState) => TSelected,
): TSelected => useSelectorBase<RootState, TSelected>(selector)
