import React, { useContext, useEffect, useState } from 'react'
import { createParam } from 'solito'
import { Link } from 'solito/link'
import PATHS from '@centrito/common/paths'
import { DownloadDialog } from '@centrito/app/components/DownloadDialog'
import { DownloadHeader } from '@centrito/app/components/DownloadHeader'
import { SearchBar } from '@centrito/app/components/SearchBar'
import { SubHomeBar } from '@centrito/app/features/shopper/home/components/SubHomeBar'
import Subhome from '@centrito/app/features/shopper/home/components/Subhome'
import { SUBHOME_OPTION_GROUP } from '@centrito/app/features/shopper/home/constants'
import NavBarLayoutContent from '@centrito/app/layouts/NavBarLayout/NavBarLayoutContent'
import NavBarLayoutPage from '@centrito/app/layouts/NavBarLayout/NavBarLayoutPage'
import { AppStateContext } from '@centrito/app/provider/shopper/appState'
import { Stack, XStack, YStack } from '@centrito/ui/src'
import SamSamLogo from '@centrito/ui/src/components/svgs/logos/SamSamLogo'

const { useParam } = createParam()

const subhomes = SUBHOME_OPTION_GROUP.map(({ href }) => (
  <Subhome subhome={href} key={`${href}-subhome`} />
))

export const MainHome: React.FC<React.HTMLAttributes<HTMLDivElement>> = () => {
  const [tab] = useParam('tab')
  const [screenContent, setScreenContent] = useState<React.ReactNode | null>(null)
  const { setIsScrollingToTop } = useContext(AppStateContext)
  useEffect(() => {
    if (tab && typeof tab === 'string') setScreenContent(subhomes[parseInt(tab)])
    else if (!tab) setScreenContent(subhomes[0])
    else return
  }, [tab])

  return (
    <React.Fragment>
      <DownloadDialog />
      <NavBarLayoutPage isWhatsAppButton isHome backgroundColor="$backgroundGrey">
        <DownloadHeader />
        <YStack
          width="100%"
          backgroundColor="#FFFFFF"
          zIndex={99}
          position="relative"
          paddingHorizontal={12}
          paddingBottom={10}
        >
          <XStack
            backgroundColor="$pureWhite"
            alignItems="center"
            height={50}
            width="100%"
            justifyContent="space-between"
          >
            <Stack
              paddingTop={4}
              hoverStyle={{ cursor: 'pointer' }}
              onPress={(): void => setIsScrollingToTop(true)}
            >
              <SamSamLogo />
            </Stack>
            <Stack flex={1}>
              <Link href={PATHS.Search.Main}>
                <SearchBar
                  name="main-searchbar"
                  isDisabled={true}
                  placeholder="Buscar"
                  height={30}
                />
              </Link>
            </Stack>
          </XStack>
          <SubHomeBar />
        </YStack>
        <NavBarLayoutContent>{screenContent}</NavBarLayoutContent>
      </NavBarLayoutPage>
    </React.Fragment>
  )
}
