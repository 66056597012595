import React, { useEffect, useState } from 'react'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import type { BrandPublic } from '@centrito/api/nest/platform/database/domain/entities/brand'
import { trpc } from '@centrito/app/api/trpc'
import { BrandsByCategoriesSheet } from '@centrito/app/features/shopper/home/components/BrandsSheet'
import { ScrollView, XStack, YStack, useWindowDimensions } from '@centrito/ui/src'
import { SeeMoreButton } from '@centrito/ui/src/components/button'
import { SubtitleText3 } from '@centrito/ui/src/components/text/new'
import { BrandSelector } from './BrandSelector'

export const BrandScrollView: React.FC = () => {
  const data = trpc.catalog.brand.findManyByCategory.useQuery({
    isOnlyRecognizedBrands: true,
  }).data

  const [isScrollView, setIsScrollView] = useState<boolean>(true)
  const { width: windowWidth } = useWindowDimensions()
  const [topElements, setTopElements] = useState<BrandPublic[]>([])
  const [emptyElements, setEmptyElements] = useState<number>(0)
  const [bottomElements, setBottomElements] = useState<BrandPublic[]>([])

  const displayItems = Math.round(windowWidth / 85)

  useEffect(() => {
    const brands = data?.brands

    if (isNil(brands)) return
    const filteredBrands = brands.filter((brand) => !isEmpty(brand.imageUrl))

    if (filteredBrands.length >= 10) {
      setIsScrollView(true)
      const middleIndex = Math.floor(filteredBrands.length / 2)
      if (filteredBrands.length % 2 === 0) {
        setTopElements(filteredBrands.slice(0, filteredBrands.length / 2))
        setBottomElements(filteredBrands.slice(filteredBrands.length / 2))
      } else {
        setTopElements(filteredBrands.slice(0, middleIndex + 1))
        setBottomElements(filteredBrands.slice(middleIndex + 1))
      }
    } else {
      const totalElements = filteredBrands.length - displayItems
      if (totalElements > displayItems) {
        setIsScrollView(true)
        setTopElements(filteredBrands.slice(0, totalElements))
        setBottomElements(filteredBrands.slice(totalElements))
      } else {
        setIsScrollView(false)
        setTopElements(filteredBrands.slice(0, displayItems))
        setBottomElements(filteredBrands.slice(displayItems))
      }
    }
  }, [data, displayItems])

  useEffect(() => {
    setEmptyElements(topElements.length - bottomElements.length)
  }, [bottomElements, topElements])
  const [isOpen, setIsOpen] = useState<boolean>(false)
  return (
    <YStack width="100%">
      <XStack
        display="flex"
        justifyContent="space-between"
        paddingHorizontal={15}
        marginBottom={20}
      >
        <SubtitleText3 paddingTop={4}>Reconocidas en belleza</SubtitleText3>
        <SeeMoreButton
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          text={'Ver todo'}
          color="$samsamBlue"
          isSheetTrigger
        >
          <BrandsByCategoriesSheet />
        </SeeMoreButton>
      </XStack>

      <ScrollView
        showsHorizontalScrollIndicator={false}
        horizontal
        alwaysBounceHorizontal={false}
        alwaysBounceVertical={false}
        bounces={false}
        overScrollMode="never"
      >
        <XStack paddingHorizontal={10} gap={10}>
          <BrandSelector
            isScrollView={isScrollView}
            bottomElements={bottomElements}
            emptyElements={emptyElements}
            topElements={topElements}
            showLabel={false}
          />
        </XStack>
      </ScrollView>
    </YStack>
  )
}
