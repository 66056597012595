import React from 'react'
import { Circle, XStack } from '@centrito/ui/src'
import SamsamWinkingLogo from '@centrito/ui/src/components/svgs/logos/SamsamWinkingLogo'
import { ContentBoldText } from '@centrito/ui/src/components/text/new'

interface SamsamWinkingLogoWithTextProps {
  size?: 'small' | 'big'
  text: string
}

export const SamsamWinkingLogoWithText: React.FC<SamsamWinkingLogoWithTextProps> = ({
  size = 'small',
  text,
}) => {
  const circleSize = size === 'small' ? 30 : 56
  const logoSize = size === 'small' ? 20 : 35
  const textSize = size === 'small' ? 14 : 15

  return (
    <XStack alignItems="center" gap={10}>
      <Circle borderWidth={1} borderColor="$neutral300" size={circleSize}>
        <SamsamWinkingLogo width={logoSize} height={logoSize} />
      </Circle>
      <ContentBoldText color="$neutral1000" fontSize={textSize}>
        {text}
      </ContentBoldText>
    </XStack>
  )
}
