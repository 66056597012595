import React, { type SVGProps } from 'react'

export const BancolombiaLogoSVG: React.FC = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="25"
    fill="none"
    viewBox="0 0 23 25"
  >
    <g>
      <g>
        <g>
          <g>
            <path
              fill="#000"
              d="M14.706 6.128c-2.513.323-4.94.8-7.373 1.534-.317.103-.512.472-.432.8.177.73.267 1.095.448 1.827.077.31.376.469.679.364 2.486-.797 4.978-1.342 7.55-1.738.294-.041.455-.359.347-.677a174.07 174.07 0 00-.577-1.672c-.099-.283-.368-.469-.642-.438zm2.102 4.062c-3.934.522-7.765 1.535-11.398 3.208-.265.13-.423.478-.368.784.143.782.214 1.173.36 1.957.062.335.363.49.659.339 3.69-1.76 7.589-2.88 11.589-3.547.254-.044.384-.347.286-.66-.201-.648-.303-.971-.51-1.616-.097-.3-.359-.5-.618-.465zm-.047 4.362c-2.456.555-4.858 1.224-7.236 2.075a.642.642 0 00-.392.751c.189.726.283 1.09.475 1.819.09.342.46.517.8.382a69.359 69.359 0 017.232-2.28c.235-.06.351-.348.264-.644-.194-.654-.291-.98-.49-1.632-.097-.32-.382-.529-.653-.47z"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
