import React from 'react'
import { X } from '@tamagui/lucide-icons'
import { VisuallyHidden } from '@tamagui/visually-hidden'
import { Dialog, Stack, XStack, YStack } from '@centrito/ui/src'
import { PrimaryButton } from '@centrito/ui/src/components/button'
import Cash from '@centrito/ui/src/components/svgs/assets/Cash'
import SamSamHappyLogo from '@centrito/ui/src/components/svgs/logos/SamSamHappyLogo'
import { BancolombiaLogoSVG } from '@centrito/ui/src/components/svgs/logos/banks/BancolombiaLogo'
import { NequiMinimalist } from '@centrito/ui/src/components/svgs/logos/banks/NequiMinimalist'
import { ContentStandardText, HighlightBoldText } from '@centrito/ui/src/components/text/new'

export interface TrustPaymentModalProps {
  isOpen: boolean
  onOpenChange: (open: boolean) => void
}

export const TrustPaymentModal: React.FC<TrustPaymentModalProps> = ({ isOpen, onOpenChange }) => {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange} modal>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content borderWidth={0} marginHorizontal={20} maxWidth={294}>
          <VisuallyHidden>
            <Dialog.Title>Opciones de Pago Confiable</Dialog.Title>
          </VisuallyHidden>
          <YStack alignItems="center" gap="$3" flex={1} position="relative">
            <Stack
              alignSelf="flex-end"
              onPress={(): void => {
                onOpenChange(false)
              }}
              position="absolute"
            >
              <X size={24} color="$Gray2" />
            </Stack>
            <SamSamHappyLogo />
            <HighlightBoldText color="$secondary500">Confiable</HighlightBoldText>
            <ContentStandardText color="$baseBlack" textAlign="center">
              Paga cuando recibas tu pedido con efectivo, Nequi o Bancolombia
            </ContentStandardText>
            <XStack justifyContent="center" alignItems="center" gap={22}>
              <Cash width={32} />
              <BancolombiaLogoSVG width={29} height={29} />
              <NequiMinimalist width={29} height={29} />
            </XStack>
            <PrimaryButton
              width="100%"
              onPress={(): void => {
                onOpenChange(false)
              }}
            >
              Quiero comprar
            </PrimaryButton>
          </YStack>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog>
  )
}
