import React from 'react'
import { Link } from 'solito/link'
import PATHS from '@centrito/common/paths'
import useWindowDimensions from '@centrito/app/utils/hooks/useWindowDimensions'
import posthogClient from '@centrito/app/utils/services/analytics/posthog'
import { XStack } from '@centrito/ui/src'
import { AppSam15, CatMuj } from '@centrito/ui/src/components/svgs/banners/'

const PADDING_HORIZONTAL = 15
const GAP = 10
interface SubhomeProps {
  subhome: string
}

export const CouponBanner: React.FC<SubhomeProps> = ({ subhome }) => {
  const { width } = useWindowDimensions()
  const adjustedWidth = width - PADDING_HORIZONTAL * 2 - GAP
  return (
    <Link href={PATHS.Account.Coupons}>
      <XStack
        justifyContent="space-between"
        paddingHorizontal={PADDING_HORIZONTAL}
        gap={GAP}
        paddingBottom={10}
        onPress={(): void => {
          posthogClient.captureCustomEvent('shopper_subhome_coupon_banner_pressed', {
            subhome,
          })
        }}
      >
        <AppSam15 width={adjustedWidth / 2} />
        <CatMuj width={adjustedWidth / 2} />
      </XStack>
    </Link>
  )
}
