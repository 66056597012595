import React from 'react'
import { BackToTopButton } from '@centrito/app/components/BackToTopButton'
import { WhatsAppButton } from '@centrito/app/components/WhatsAppButton'
import { YStack, isClient, styled, useWindowDimensions } from '@centrito/ui/src'
import { MOBILE_WIDTH_STYLES } from '@centrito/ui/src/constants'

const StyledNavBarLayoutPage = styled(YStack, {
  ...MOBILE_WIDTH_STYLES,
  margin: 0,
  height: '100%',
  overflow: 'hidden',
  justifyContent: 'center',
  className: 'catalog-page',
  backgroundColor: '#FFFFFF',
  zIndex: 10,
})

interface NavBarLayoutPageProps {
  isWhatsAppButton?: boolean
  isHome?: boolean
  backgroundColor?: string
  children: React.ReactNode
}

const NavBarLayoutPage: React.FC<NavBarLayoutPageProps> = ({
  isWhatsAppButton = false,
  isHome = false,
  children,
  backgroundColor = '#FFFFFF',
}) => {
  const { height } = useWindowDimensions()
  return (
    <StyledNavBarLayoutPage backgroundColor={backgroundColor}>
      {isWhatsAppButton && (
        <YStack
          zIndex={999}
          position="absolute"
          top={isHome ? '60%' : isClient ? '60%' : height * 0.6 - 40}
          right={0}
          gap={10}
        >
          <WhatsAppButton />
          <BackToTopButton />
        </YStack>
      )}
      {children}
    </StyledNavBarLayoutPage>
  )
}

export default NavBarLayoutPage
