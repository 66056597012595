import * as React from 'react'
import type { SvgProps } from 'react-native-svg'
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg'

const Cash = (props: SvgProps) => (
  <Svg
    viewBox="0 0 183 94"
    preserveAspectRatio="xMinYMin slice"
    fill="none"
    width={props.width ?? 183}
    height={props.width ? ((props.width as number) * 94) / 183 : 94}
    {...props}
  >
    <G clipPath="url(#a)">
      <Path
        fill="#EEE"
        d="M3.71.48c.03-.26.21-.35.45-.35h171.91c.31 0 .64.12.92-.12h.9c.4.26.85.08 1.28.12.25.02.53-.03.51.36-.09.11-.22.14-.35.12-1.08-.21-2.17-.11-3.25-.11H6.15C5.49.5 4.83.44 4.18.62c-.17.05-.34.02-.46-.14h-.01ZM0 6.01h.24c.17.13.18.33.19.52V87.73c0 .36.07.73-.1 1.07-.1.12-.21.14-.34.06V6.01H0Z"
      />
      <Path
        fill="#EFEFEF"
        d="M0 88.86h.24c.27.41.26.9.43 1.34.08.19.14.39.26.56.04.05.07.1.09.16.07.17.16.33.2.51 0 .1-.01.18-.1.24-.52.12-.7.67-1.13.89v-3.71l.01.01Z"
      />
      <Path
        fill="#191D19"
        d="M3.71.48C5.27.05 6.87.26 8.45.26 64.2.25 119.96.26 175.71.25h1.41c.86 0 1.73-.08 2.56.24 1.21.52 2.02 1.39 2.32 2.7.14.31.2.64.3.96v82.82c0 .45.02.89 0 1.34-.12.31-.19.64-.31.95-.09.25-.18.5-.27.74-.11.45-.33.84-.65 1.17-.12.05-.21.12-.24.26-.24.36-.54.66-.9.9-.13.11-.29.15-.46.15-.82-.55-1.7-.35-2.57-.22-.34.05-.68.04-1.02.04H8.39c-.89 0-1.73-.24-2.59-.32-.35-.03-.55-.3-.75-.55-.59-.73-.76-.76-1.65-.54-.34.08-.67.03-.93.37-.14.18-.4 0-.57-.11-.19-.14-.2-.35-.06-.51.51-.61.14-1.31.23-1.96.02-.12-.03-.27-.14-.34-.61-.43-.47-1.06-.42-1.63.13-1.57.22-3.14.2-4.72-.3-24.9-.05-49.81-.14-74.71 0-1.1.13-2.2.02-3.31-.06-.57.28-1.1.69-1.53.1-.1.25-.17.27-.5-.33.65-.88.99-.99 1.63-.04.23-.22.43-.48.45-.26.02-.29-.19-.31-.39.1-.36.19-.73.41-1.05.34-.63.85-1.11 1.38-1.56.25-.26.6-.34.9-.5l.25-.03V.48Z"
      />
      <Path
        fill="#1D1E1C"
        d="M.9 3.47c.04.14-.09.41.16.4.16 0 .34-.2.34-.41 0-.82.79-1.17 1.08-1.82.04-.09.27-.31.3-.03.02.23.57.42.11.67-.52.28-.69.74-.97 1.23-.31.55.02.93-.04 1.36-.07.54-.1 1.12-.1 1.69.01 24.44 0 48.88.02 73.32 0 1 .12 1.99.13 2.99.01 1.55-.2 3.09-.28 4.64-.01.19-.1.36.17.46.63.23.59.76.44 1.25-.08.24-.06.44.08.59.27.3.11.48-.11.68-.49.44-.48.44.19.72.15-.13-.02-.28.02-.42.09-.28.24-.52.52-.2.27.31.38.32.55-.08.1-.24.38-.25.61-.09.48.33 1.06.55 1.29 1.16.05.13.16.23.29.21.76-.11 1.45.28 2.2.28h168.47c.62 0 1.2-.18 1.82-.19.44-.01.83-.19 1.06.36.03.07.23.08.35.11-.15.59-.72.42-1.09.6-.27.2-.61.16-.92.2-.19.02-.38 0-.57 0H5.42c-.3 0-.6-.05-.89.06-.08-.07-.16-.07-.24 0-.52-.14-1.09-.04-1.58-.31-.1-.05-.19-.08-.21-.2.09-.56-.33-.21-.51-.28-.13.01-.24-.01-.32-.12-.15-.15-.36-.25-.37-.5.07-.23.26-.2.53-.18-.29-.08-.48-.14-.67-.19-.13-.1-.27-.19-.27-.38.32-.26.47-.53 0-.78-.12-.13-.11-.3-.11-.44-.04.23.06.42.23.59.07.21.07.4-.12.55-.49-.61-.56-1.35-.63-2.09 0-2.11.02-4.21.02-6.32V6.67c0-.21-.02-.42-.02-.64 0-.37.02-.74.03-1.11.16-.29.01-.66.26-.93.13-.16-.01-.53.37-.51L.9 3.47Z"
      />
      <Path
        fill="#EFEFEF"
        d="M4.53 93.2c.39-.15.8-.08 1.2-.08 55.24 0 110.47 0 165.71-.01 2.36 0 4.72.19 7.07-.17-.01.22-.16.27-.35.27H4.53v-.01ZM182.21 88.46c0-1.24.02-2.47.02-3.71V5.25c0-.43-.01-.85-.02-1.28-.11-.18-.15-.36.03-.52.09.79.31 1.57.15 2.38-.04.23-.04.47-.04.7v80.58c0 .54.14 1.1-.09 1.63-.1-.08-.11-.17-.05-.28ZM2.52 92.59c.33.49.93.27 1.35.51.12.07.28.07.42.1-.75.09-1.46-.03-2.12-.39-.21-.11-.48-.21-.51-.51.13 0 .25.01.38.02.16.08.36.09.47.27h.01Z"
      />
      <Path
        fill="#191D19"
        d="M1.15 91.42c.16-.12.96 0 .95.13-.03.22-.15.43-.41.26-.16-.11-.26-.07-.4 0-.05-.04-.1-.08-.16-.12 0-.09.01-.17.02-.26v-.01Z"
      />
      <Path
        fill="#EFEFEF"
        d="M178.5 92.94c.33-.26.83-.21 1.09-.6.11 0 .22-.02.33-.03-.32.55-.83.69-1.42.63ZM179.92 92.31l.9-.9c-.1.5-.39.8-.9.9ZM181.06 91.16l.65-1.17c.05.54-.16.93-.65 1.17Z"
      />
      <Path fill="#7D7D7D" d="m182.25 3.45-.03.52c-.37-.18-.21-.51-.22-.8.19 0 .2.15.25.27v.01Z" />
      <Path
        fill="#A1A3A2"
        d="M182.21 88.46c.02.09.03.18.05.28-.04.2-.08.4-.28.51.02-.28-.14-.61.23-.79Z"
      />
      <Path
        fill="#EEE"
        d="M.9 3.47c-.2.12-.25.34-.37.51-.04-.59.23-1.04.65-1.42-.09.3-.19.6-.28.9v.01ZM3.46.51c-.22.32-.59.34-.9.5.14-.45.49-.54.9-.5Z"
      />
      <Path fill="#EEE" d="M.53 3.97c.04.34-.03.66-.26.93.08-.31-.25-.74.26-.93Z" />
      <Path
        fill="#191D19"
        d="M.87 90.95c0-.23.01-.46.02-.68.22.06.6-.15.61.25 0 .34-.33.45-.62.52L.85 91l.02-.04v-.01Z"
      />
      <Path
        fill="#A1CBA7"
        d="M2.56 3.96c.08-.1.17-.19.25-.29.23-.44.66-.51 1.08-.59.25.01.51.03.76.03h172.06c1.1 0 2.08.23 2.76 1.17.27.37.46.77.46 1.26v3.9c0 26.34 0 52.68.02 79.02 0 1.24-.6 1.78-1.88 1.78-11.53.02-23.06 0-34.59 0H5.88c-1.39 0-2.55-.37-3.21-1.73-.09-.18-.07-.37-.15-.54-.16-.23-.11-.49-.11-.74V4.78c0-.27-.04-.56.13-.8l.02-.02Z"
      />
      <Path
        fill="#1D1E1C"
        d="M2.56 3.96v83.56c0 .15 0 .3-.01.45-.18-.03-.24-.15-.24-.32V4.88c0-.32-.15-.7.26-.92h-.01ZM3.9 3.08c-.36.19-.77.3-1.08.59.13-.63.6-.63 1.08-.59Z"
      />
      <Path
        fill="#191D19"
        d="M178.17 92.76H3.3v-.13h174.87v.13ZM.51 45.38V6.59c0-.63.09-1.27.12-1.9.01-.24.11-.37.36-.32.2.04.28.17.2.38C.84 5.71.91 6.73.91 7.7.87 32.6.87 57.51.91 82.41c0 1.34.04 2.71.35 4.04.18.77-.27 1.44-.27 2.18 0 .24-.19.17-.32.06-.21-.18-.19-.43-.17-.65.15-1.25.08-2.49.01-3.74-.08-1.55-.02-3.11-.02-4.66V45.38h.02ZM2.52 92.59c-.23.04-.39-.04-.47-.27.34-.31.61.16.97 0-.1.34-.34.19-.49.26l-.01.01ZM.9 90.27c-.5-.29-.19-.69-.13-1.06.18.33.1.7.13 1.06ZM4.73 91.28c-.25.18-.42.18-.57.04-.06-.05-.05-.17.03-.22.21-.12.34.04.54.17v.01ZM4.64 91.84c0 .13-.08.18-.19.19-.09.01-.16-.02-.19-.11-.04-.12.02-.21.14-.22.11-.01.2.03.24.14Z"
      />
      <Path
        fill="#1C1C1D"
        d="M8.33 27.87c.66-1.32 1.78-2.1 3.18-2.35 2.48-.44 4.8-1.23 6.88-2.64 2.35-1.59 4.19-3.63 5.44-6.2.67-1.37 1.03-2.81 1.36-4.28.05-.57.19-1.13.37-1.67.31-.88.9-1.56 1.6-2.17.8-.69 1.77-.93 2.77-.99 1-.06 2 0 3 0h120.42c2.26 0 3.69 1.25 3.97 3.63.17 1.46.66 2.87 1.27 4.24.86 1.93 2.05 3.6 3.5 5.09 1.44 1.47 3.09 2.66 4.98 3.53 1.16.53 2.37.91 3.62 1.14.27.21.65 0 .9.24 1.6.15 2.85 1.22 3.25 2.94.12.53.17 1.03.17 1.55 0 10.78.02 21.55-.01 32.33 0 1.92-.61 3.64-2.25 4.85-.57.42-1.22.63-1.89.75-1.29.24-2.56.53-3.78.97-.92.33-1.77.88-2.65 1.33-.37-.24-.75-.37-1.16-.07-.15.11-.35.18-.51 0-.14-.17-.11-.36-.01-.53.22-.4.53-.77.48-1.27.81-.89 1.85-1.42 2.9-1.92 1.7-.81 3.5-1.34 5.35-1.67.82-.15 1.04-.41 1.04-1.25V30.92c0-1.32-.75-2.11-2.07-2.32-.75-.12-1.52-.22-2.23-.53-.39-.21-.77-.45-1.23-.42-3.61-1.34-6.53-3.55-8.79-6.69-1.48-2.06-2.37-4.35-3.08-6.75-.09-.31-.09-.63-.08-.95.03-.35-.01-.68-.25-.96-.42-1.17-1.29-1.62-2.48-1.59H29.83c-.34 0-.68 0-1.02.02-.87.08-1.28.45-1.34 1.33-.15 2.21-1.09 4.14-2.04 6.07-1.04 2.1-2.51 3.9-4.19 5.52-.72.69-1.5 1.33-2.37 1.83-.32.17-.72.2-.96.52-1.12.7-2.27 1.37-3.58 1.69-.45-.03-.8.25-1.19.39-.87.23-1.7.72-2.66.37-.51-.22-.71.09-.82.49-.08.29-.03.59-.03.89v32.53c0 .38.01.77-.01 1.15-.06.23-.09.44 0 .68.11.29-.03.51-.33.58-.33.08-.47-.12-.51-.41-.05-.34-.1-.68-.11-1.02-.02-.57 0-1.14-.45-1.6-.16-.17-.11-.45-.11-.68v-12.2c0-.17-.03-.36.04-.51.45-.95.38-1.89 0-2.84-.09-.23-.04-.51-.04-.76V37.4c0-.32 0-.64-.18-.92-.15-.76-.06-1.52-.07-2.28-.02-1.04 0-2.08 0-3.12-.02-1.02.1-2.02.52-2.97.04-.08 0-.16-.06-.24h.01Z"
      />
      <Path
        fill="#1C1C1C"
        d="M8.06 36.44c.32.16.25.46.25.73v7.79c0 .37.13.69.24 1.03.23.7.33 1.45-.09 2.13-.14.22-.15.43-.15.67v12.38c0 .33.01.63.37.8.2.1.34.32.26.53-.24.64.16 1.25.03 1.88-.03.13 0 .28.17.29.1 0 .2-.04.3-.09.15-.08.13-.2.04-.31-.2-.24-.13-.47 0-.71.08-.07.16-.08.26-.05.15.11.24.29.39.4.52.49 1.18.71 1.84.83 2.35.41 4.58 1.15 6.62 2.38 1.52.91 2.89 2 4.1 3.31.75.8 1.42 1.63 2 2.55 1.1 1.75 2.07 3.57 2.47 5.63-.01.27.06.51.21.73.14.68.32 1.34.44 2.02.04.21.09.41 0 .61-.1.8.08.91.83 1.08 1.52.35 3.05.11 4.57.11 39.12.01 78.25.01 117.37 0 .9 0 1.8.37 2.49.02-.57 0-1.37-.01-2.17-.01H33.01c-1.08 0-2.17.05-3.25.06-.32 0-.72.03-.76-.47.07-.12.19-.17.32-.2.25-.03.51-.02.77-.02h124.05c.07 0 .14 0 .21.03.5.36.82-.05 1.15-.3.34-.25.23-.59.05-.91-.08-.13-.15-.26-.04-.41.19-.15.3-.34.28-.56 0 .18-.04.38-.23.51-.08.08-.13.22-.29.19a.309.309 0 0 1-.11-.16.708.708 0 0 1 0-.29c.14-.29.19-.6.19-.91.1-.33.1-.72.5-.88.43.1.29-.31.41-.48.17-.23.2-.61.59-.56.36.05.38.38.46.68.25.93.03 1.76-.51 2.52-.35.49-.55 1-.44 1.61.04.23 0 .46-.05.69-.07.25-.18.44-.48.38-.51-.09-.74.21-.9.61-.13.32-.31.6-.59.82-.07.04-.14.06-.21.07-.62.05-1.23.13-1.83-.14a.475.475 0 0 0-.37 0c-.7.27-1.43.21-2.15.21H34.09c-1.85 0-3.7.15-5.55-.11-.36-.05-.72.05-1.08.08-.08.03-.16.05-.24.08-1.25-.54-1.74-1.65-1.96-2.85-.46-2.55-1.34-4.9-2.75-7.09-1.54-2.39-3.63-4.14-6-5.6-.9-.56-1.92-.92-2.93-1.25-.52-.12-1.01-.33-1.54-.38-.26-.24-.64-.01-.91-.22-2.26-.17-3.58-1.81-3.58-4.06V31.97c0-.91-.03-1.83.26-2.71.18.14.15.33.15.52-.01 11.31 0 22.61 0 33.92v1.11c.04.08.1-.06.03 0-.03.02-.05 0-.05-.04.04-9.17 0-18.35.02-27.52 0-.27-.08-.56.13-.8l-.03-.01Z"
      />
      <Path
        fill="#181E18"
        d="M154.35 85.03c.11-.38.36-.7.52-1.06.15-.35.43-.73.95-.45.16.09.29.07.32-.14.03-.21.11-.43.06-.62-.22-.81.17-1.4.58-2.05.35-.56.69-1.23.37-1.95-.13-.28.16-.82-.35-.82-.42 0-.26.57-.48.8-.19.21-.12.49-.27.73-.25-.17-.12-.43-.18-.63-.13-.14-.11-.31-.06-.48.6-2.13 1.65-4.03 3.01-5.76.21-.22.43-.43.57-.7.11-.16.24-.29.45-.3.34.02.41.24.39.53 0 .14-.09.32.11.38.21.07.37-.03.49-.2.13-.17.21-.37.24-.58.04-.31.19-.51.51-.57-.31 0-.59.08-.84.27-.16.13-.36.32-.56.19-.26-.18-.03-.41.02-.62.74-.94 1.64-1.71 2.59-2.43.14-.11.29-.18.46-.07.19.04.33.08.15.32-.2.27-.35.57-.53.86-.07.12-.1.24 0 .35.14.16.22-.03.31-.08.48-.23.97-.55 1.27.22h-.01c-.29.21-.64.34-.88.62-.33.08-.56.32-.79.55-.26.15-.42.38-.62.59-.62.67-1.25 1.32-1.82 2.04-.95 1.2-1.62 2.53-2.16 3.94-.45 1.18-.69 2.4-.93 3.64-.26 1.33-.9 2.49-2.01 3.33-.15.11-.28.16-.46.15-.14.08-.28.11-.42-.01v.01Z"
      />
      <Path
        fill="#1C1C1C"
        d="M154.35 85.03c.14 0 .28 0 .42.01-.33.43-.82.57-1.31.61-.74.05-1.49.04-2.24.04H30.68c-.83 0-1.66-.02-2.49-.01-.38 0-.71-.11-.98-.36l.04-.04c.11-.08.23-.12.36-.1 2.55.33 5.1.13 7.65.13h116.77c.78 0 1.57.02 2.32-.28Z"
      />
      <Path
        fill="#0F1811"
        d="M8.06 36.44v28.49h-.25V29.83c0-.19 0-.38-.01-.57 0-.53.31-.94.54-1.38.29 0 .39.09.23.38-.36.65-.47 1.38-.49 2.09-.06 2.03-.02 4.07-.02 6.1v-.01Z"
      />
      <Path fill="#546859" d="M25.57 10.73c.08.6-.25 1.11-.37 1.67-.03-.59-.04-1.18.37-1.67Z" />
      <Path
        fill="#6E8471"
        d="M12.02 68.14c.56-.06 1.07.07 1.54.38-.57.09-1.03-.26-1.54-.38ZM11.11 67.92c.31.03.7-.2.91.23-.32-.01-.69.17-.91-.23Z"
      />
      <Path fill="#1C1C1D" d="M163.56 70.8c.11-.47.54-.48.88-.61-.25.26-.55.46-.88.61Z" />
      <Path fill="#5D7263" d="M171.61 25.45c-.33.02-.67.08-.9-.24.31.04.69-.18.9.24Z" />
      <Path fill="#1C1C1D" d="M162.15 71.94c.04-.37.24-.57.62-.59-.12.28-.36.45-.62.59Z" />
      <Path
        fill="#C9E8C9"
        d="M163.25 68.51c-.8.35-1.4.98-2 1.58-.31.31-.7.58-.89 1-.24.16-.3.48-.54.63-.26-.09-.2.22-.34.28-.18.21-.38.39-.52.63-1.12 1.55-2.09 3.19-2.73 4.99-.14.4-.46.76-.36 1.23-.23.27-.29.61-.36.94-.23.26-.09.62-.25.9 0 .08-.01.16-.02.24-.15.31-.03.64-.07.96-.05.43-.05.91-.69.85-.04-.01-.09-.03-.13-.04-.2-.17-.45-.1-.68-.1H30.22c-.31 0-.64-.08-.94.11l-.26.03c-.68.19-1.28-.25-1.28-.91-.23-.84-.5-1.67-.52-2.55-.19-.18-.05-.48-.22-.66-.4-1.53-1-2.98-1.76-4.38-1.77-3.25-4.33-5.7-7.55-7.43-1.8-.96-3.75-1.6-5.81-1.88-.7-.09-1.45-.33-1.9-1.01-.15-.34-.33-.68-.39-1.06-.01-11.11 0-22.22 0-33.32 0-.86.31-1.2 1.02-1.18.9.05 1.72-.24 2.56-.5.42-.02.78-.23 1.17-.35 1.23-.39 2.4-.91 3.46-1.66.27-.16.59-.23.85-.43 1.52-1.07 2.92-2.27 4.12-3.69 1.23-1.46 2.24-3.06 3.04-4.79.57-1.25 1-2.55 1.38-3.87.17-.59 0-1.22.27-1.81.17-.37.38-.57.77-.66.65-.15 1.31-.11 1.97-.11 40.27 0 80.53 0 120.8.02.74 0 1.48 0 2.23.06.86.07 1.57.86 1.78 1.83.25.26 0 .65.24.91.08 1.49.66 2.86 1.23 4.19.88 2.05 2.12 3.9 3.69 5.5 1.75 1.79 3.8 3.15 6.07 4.17.25.11.54.17.79.31.37.17.77.26 1.16.37 1.02.38 2.14.38 3.16.78.93.35 1.4 1.01 1.4 2.05 0 11.06 0 22.11.02 33.17 0 .69-.35.96-1.28 1.1-2.96.45-5.62 1.6-8.06 3.28-.08.05-.11.17-.16.26l-.02.02Z"
      />
      <Path
        fill="#0F1811"
        d="M10.61 28.37c-.61.18-.85.57-.85 1.22.01 10.91 0 21.83 0 32.74 0 .21-.02.42-.03.63.04.19.13.38-.03.57h-.25V29.34c0-.49.02-.92.53-1.2.33-.18.51-.13.63.22v.01Z"
      />
      <Path fill="#7D8C7D" d="M168.17 27.86c-.46.09-.83-.06-1.16-.37.45-.07.81.14 1.16.37Z" />
      <Path fill="#636E65" d="M14.34 27.51c-.32.36-.73.41-1.17.35.39-.12.72-.46 1.17-.35Z" />
      <Path fill="#6D7A6F" d="M155.23 13.31c-.4-.22-.2-.6-.24-.91.42.21.21.59.24.91Z" />
      <Path fill="#6F7D71" d="M18.65 25.42c-.23.25-.47.47-.85.43.25-.21.49-.44.85-.43Z" />
      <Path
        fill="#181E18"
        d="M154.35 85.03c-.31.39-.78.4-1.21.51-.14.03-.3 0-.45 0-41.22 0-82.44 0-123.65.01-.63 0-1.2-.18-1.8-.28.42-.47.98-.38 1.5-.35 1.28.1 2.56.39 3.86.11.22-.05.47-.01.7-.01h117.28c.47 0 .94.06 1.34-.29.06-.06.24-.09.29-.04.64.59 1.42.24 2.14.34ZM27.75 81.83c.31.47.7.83 1.28.91.42.68 1.08.33 1.62.32.39 0 .8-.08 1.2-.08 40.13 0 80.26 0 120.39-.01.57 0 1.13.25 1.7.03.14-.05.33.02.34.2.01.17-.16.16-.29.16-.62-.02-1.21.06-1.82.23-.53.15-1.04-.25-1.61-.24-10.55.02-21.1.02-31.65.02H33.43c-1.5 0-3 .09-4.5-.12-.18-.03-.38.04-.57.06-.32.03-.79-.23-.78-.28.11-.39-.44-.89.16-1.19l.01-.01Z"
      />
      <Path
        fill="#DAF0DA"
        d="M29.29 82.71c.04-.23.2-.24.38-.24h123.61c.27 0 .55.06.82-.08.55-.28.21.17.25.31-.28.02-.55.04-.83.04H30.43c-.38 0-.76-.03-1.15-.04l.01.01Z"
      />
      <Path
        fill="#181E18"
        d="M154.48 82.74c.38-.17.56-.47.53-.89.22-.22-.04-.6.27-.79.07-.04.15-.09.22-.13-.02.28.32.34.35.56.05.28.16.69-.09.83-.39.21-.67.64-1.17.62-.04 0-.08-.13-.13-.2h.02Z"
      />
      <Path
        fill="#181E18"
        d="M155.5 80.93c.07-.36.14-.71.2-1.07.05.01.11.03.16.04-.16.33.43.85-.36 1.03Z"
      />
      <Path fill="#849283" d="M155.28 81.06c-.13.25.14.63-.27.79 0-.32-.16-.69.23-.92l.04.13Z" />
      <Path fill="#819082" d="M155.26 80.69c.02-.32-.17-.69.25-.9.06.34-.04.64-.25.9Z" />
      <Path
        fill="#1C1C1D"
        d="M9.71 63.54c0-.19.02-.38.03-.57.13.31.38.58.25.96-.26-.01-.26-.2-.27-.39h-.01Z"
      />
      <Path fill="#809483" d="M27.01 78.62c.37.12.22.42.22.66-.34-.13-.24-.41-.22-.66Z" />
      <Path
        fill="#1C1C1D"
        d="M159.81 71.71c.05-.32.26-.51.54-.63-.03.16-.2.27-.12.51.4-.29.77-.61 1.28-.66.09 0 .17-.22.3-.13.06.04.07.19.07.29 0 .15-.04.34-.24.3-.42-.08-.34.2-.41.44-.12.47-.26.96-.91.93-.32-.01-.49-.1-.32-.46.12-.24.2-.5-.19-.58v-.01Z"
      />
      <Path fill="#708572" d="M158.96 72.63c0-.35.24-.51.52-.63-.07.29-.25.5-.52.63Z" />
      <Path
        fill="#1B1C1B"
        d="M64.82 39.12c.13-.99.59-1.87.94-2.77.77-2.02 1.84-3.9 3.06-5.7 1.76-2.6 3.92-4.83 6.42-6.71 2.25-1.7 4.71-3.05 7.38-3.99.17-.21.47-.06.65-.25.94-.17 1.83-.54 2.77-.75.48-.11.95-.11 1.41-.18.27-.22.65 0 .93-.22 2.21-.13 4.42-.13 6.63 0 .28.22.65 0 .93.21 1.32.06 2.54.49 3.79.86 1.14.33 2.23.77 3.31 1.25 1.61.71 3.11 1.61 4.52 2.65 2.55 1.86 4.73 4.07 6.5 6.69.96 1.42 1.84 2.89 2.46 4.5.07.19.17.37.26.56.16.33.29.68.37 1.04.09.25.18.5.27.76.19.17.04.48.24.65-.05.34.26.56.27.88.05.57.31 1.1.37 1.67.26.26.02.64.24.91.42 2.5.35 5.01.27 7.52-.28.44.05.99-.23 1.43-.05 1.36-.49 2.63-.81 3.94-.33 1.31-.81 2.56-1.32 3.78a25.27 25.27 0 0 1-2.33 4.27c-1.37 2.05-2.94 3.94-4.79 5.58-1.57 1.38-3.23 2.63-5.05 3.66a27.752 27.752 0 0 1-5.6 2.41c-3.82 1.18-7.71 1.44-11.67.89-2.01-.28-3.95-.79-5.84-1.49-2.01-.74-3.87-1.75-5.63-2.96-2.19-1.51-4.12-3.3-5.78-5.37-1.56-1.94-2.81-4.07-3.8-6.37-.98-2.31-1.62-4.68-2.03-7.15-.38-2.31-.19-4.62-.2-6.93.26-.44-.08-.99.24-1.42.03-.79.31-1.53.38-2.31.09-.3.18-.59.26-.89.19-.18.05-.48.24-.66l-.03.01Z"
      />
      <Path
        fill="#1C1C1C"
        d="M148.35 59.46c-1.23.36-2.48.65-3.76.57-2.77-.17-5.29-1-7.52-2.74-2.03-1.59-3.36-3.64-4.14-6.02-.54-1.66-.76-3.42-.55-5.19.38-3.16 1.5-6.01 3.68-8.39 1.59-1.73 3.5-2.95 5.7-3.72.46-.16.94-.37 1.46-.35.35-.26.82.03 1.17-.23 1.42-.11 2.83-.03 4.21.29 1.13.26 2.24.67 3.21 1.34.25.19.59.24.84.44.8.74 1.7 1.38 2.38 2.26 1.18 1.52 2.06 3.15 2.58 5.03.59 2.11.55 4.18.17 6.29-.05.29-.28.53-.25.84-.2.17-.05.48-.24.66-.1.81-.53 1.49-.91 2.18-.88 1.62-2.01 3.03-3.42 4.23-1.09.94-2.34 1.59-3.6 2.23-.11.05-.25.03-.38.05-.18.19-.49.03-.65.25l.02-.02Z"
      />
      <Path
        fill="#1B1C1C"
        d="M40.17 33.61c1.86.23 3.49 1.05 4.95 2.16 2.01 1.52 3.43 3.48 4.28 5.89.87 2.47 1.05 4.96.46 7.49-.86 3.72-2.93 6.68-6.06 8.79-2.09 1.41-4.51 2.15-7.07 2.09-1.32-.03-2.61-.33-3.89-.75-2.1-.7-3.84-1.9-5.3-3.52-1-1.12-1.75-2.43-2.27-3.84-.59-1.59-.9-3.26-.82-4.95.17-3.52 1.34-6.65 3.76-9.28 1.17-1.27 2.51-2.32 4.07-3.05 1.03-.48 2.08-.96 3.25-1.04.28-.21.65.01.93-.21h2.78c.27.22.65 0 .92.22h.01Z"
      />
      <Path fill="#7D8F7F" d="M64.33 40.66c.1.81-.21 1.54-.38 2.31-.11-.81.17-1.55.38-2.31Z" />
      <Path fill="#515D4D" d="M118.56 50.14c.03-.48-.22-1.01.23-1.43-.03.48.19 1.01-.23 1.43Z" />
      <Path fill="#7D8F7F" d="M63.95 42.97c-.04.48.17 1-.24 1.42.04-.48-.24-1.01.24-1.42Z" />
      <Path fill="#6C7B6F" d="M118.28 40.28c-.21-.54-.46-1.07-.37-1.67.25.53.45 1.07.37 1.67Z" />
      <Path fill="#909E91" d="M144.39 33.38c-.32.45-.78.15-1.17.23.32-.45.77-.2 1.17-.23Z" />
      <Path fill="#6C7B6F" d="M118.52 41.19c-.38-.23-.23-.59-.24-.91.42.21.21.59.24.91Z" />
      <Path fill="#899688" d="M36.46 33.39c-.23.42-.61.17-.93.21.23-.4.6-.21.93-.21Z" />
      <Path fill="#677568" d="M95.93 18.77c-.32-.02-.69.19-.93-.21.32.01.69-.17.93.21Z" />
      <Path fill="#626B5E" d="M88.38 18.56c-.23.41-.6.2-.93.22.23-.39.6-.2.93-.22Z" />
      <Path fill="#788A79" d="M40.17 33.61c-.32-.04-.69.18-.92-.22.33 0 .7-.19.92.22Z" />
      <Path fill="#6C7C6C" d="M117.14 36.31c-.12-.34-.43-.63-.37-1.04.32.28.41.63.37 1.04Z" />
      <Path fill="#718170" d="M152.65 35.45c-.38.04-.6-.22-.84-.44.37-.04.61.19.84.44Z" />
      <Path fill="#636C62" d="M148.35 59.46c.11-.37.4-.24.65-.25-.12.34-.4.26-.65.25Z" />
      <Path fill="#778D78" d="M83.26 19.7c-.13.32-.4.26-.65.25.11-.35.4-.25.65-.25Z" />
      <Path fill="#616F65" d="M117.65 37.72c-.35-.11-.24-.4-.24-.65.35.11.24.4.24.65Z" />
      <Path fill="#5D6C5D" d="M157.3 50.52c.01-.24-.13-.54.24-.66-.01.24.13.54-.24.66Z" />
      <Path fill="#708076" d="M64.82 39.12c0 .25.12.54-.24.66 0-.25-.12-.54.24-.66Z" />
      <Path
        fill="#A1CBA7"
        d="M84.01 70.84c-1.49-.41-2.89-1.04-4.25-1.77-2.04-1.08-3.89-2.4-5.56-3.98-1.78-1.69-3.35-3.55-4.6-5.66-1.03-1.74-1.81-3.59-2.48-5.5-1.22-3.49-1.28-7.08-1.11-10.71.19-.37 0-.81.22-1.17.05-.7.26-1.37.37-2.05.59-2.51 1.62-4.84 2.96-7.03 1.14-1.86 2.57-3.47 4.14-4.99 1.59-1.55 3.39-2.77 5.3-3.82 1.53-.84 3.16-1.51 4.88-1.92.34-.08.65-.31 1.03-.27.26-.24.65 0 .91-.23.16-.01.33-.02.49-.04 2.15-.43 4.31-.58 6.49-.3.52.06 1.06-.09 1.57.08.62.38 1.35.25 2.02.42.87.22 1.73.49 2.6.71.16.23.47.06.64.25.81.16 1.5.62 2.24.95 2.32 1.04 4.39 2.48 6.25 4.2 2.08 1.93 3.84 4.13 5.22 6.63.77 1.39 1.42 2.83 1.93 4.32.29.85.5 1.73.73 2.61.77 2.95.52 5.93.48 8.91-.22.27 0 .65-.22.93-.01.16-.03.32-.04.49-.16.28-.03.64-.23.91-.32 1.9-1.02 3.68-1.84 5.39-1 2.07-2.29 3.95-3.85 5.66a26.127 26.127 0 0 1-5.5 4.56c-1.59.99-3.31 1.72-5.06 2.35-.39.14-.81.35-1.26.33-.26.21-.65-.01-.89.26-2.73.71-5.52.7-8.31.6-.37-.18-.81 0-1.17-.22-1.18-.03-2.29-.46-3.44-.65-.17-.2-.48-.05-.65-.24l-.01-.01Z"
      />
      <Path
        fill="#4C5E4E"
        d="M94.37 21.49c-2.69-.03-5.38-.18-8.06.22.27-.38.71-.28 1.06-.31 1.53-.16 3.06-.44 4.6-.28.8.08 1.6.25 2.4.37Z"
      />
      <Path
        fill="#637966"
        d="M66.61 40.01c.1.73-.24 1.37-.37 2.05-.06-.72.03-1.41.37-2.05ZM66.23 42.06c.04.41.07.82-.22 1.17.05-.39-.23-.85.22-1.17Z"
      />
      <Path fill="#5D735F" d="M88.1 71.74c.41-.04.82-.08 1.17.22-.4-.04-.85.24-1.17-.22Z" />
      <Path fill="#5B6F5C" d="M85.82 21.75c-.22.39-.6.21-.91.23.22-.41.6-.21.91-.23Z" />
      <Path fill="#57685A" d="M116.27 51.41c.03-.32-.18-.69.22-.93-.02.32.18.69-.22.93Z" />
      <Path fill="#637B67" d="M97.58 71.36c.2-.44.58-.22.89-.26-.23.33-.57.27-.89.26Z" />
      <Path fill="#617763" d="M115.99 52.81c-.05-.34 0-.65.23-.91-.03.31.19.7-.23.91Z" />
      <Path fill="#647866" d="M99.63 22.87c-.24-.02-.55.14-.64-.25.25 0 .52-.07.64.25Z" />
      <Path fill="#637862" d="M84.01 70.84c.24.01.54-.11.65.24-.24 0-.53.11-.65-.24Z" />
      <Path
        fill="#A1CBA7"
        d="M134.57 45.91c.07-2.53.94-4.89 2.87-6.77 2.44-2.37 5.43-3.22 8.74-2.8 3.3.42 5.91 2.09 7.73 4.88 1.73 2.64 2.43 5.55 1.57 8.65-.86 3.1-2.86 5.24-5.86 6.45-1.33.54-2.72.87-4.13.78-3.94-.25-7.11-1.88-9.24-5.34-1.09-1.77-1.63-3.66-1.69-5.85h.01ZM27.01 48.7c-.08-.92-.33-1.82-.32-2.76.07-3.3 1.51-5.87 4.08-7.82.79-.6 1.69-1.12 2.72-1.3.18-.19.48-.03.65-.24.78-.09 1.53-.3 2.33-.31 3.99-.05 7.13 1.55 9.44 4.79.88 1.24 1.41 2.61 1.78 4.09.47 1.93.29 3.79-.38 5.62-.87 2.38-2.53 4.09-4.77 5.23-1.56.79-3.24 1.22-4.98 1.12-2.07-.12-4-.69-5.79-1.84-2.15-1.38-3.49-3.33-4.48-5.6-.04-.09-.03-.21-.05-.31-.21-.17-.06-.47-.23-.66v-.01Z"
      />
      <Path fill="#637C66" d="M27.01 48.7c.37.11.21.42.23.66-.36-.12-.23-.41-.23-.66Z" />
      <Path fill="#657867" d="M34.14 36.57c-.11.37-.41.23-.65.24.12-.34.41-.24.65-.24Z" />
      <Path
        fill="#1C1B1C"
        d="M98.08 58.04c-1.07.87-2.25 1.53-3.54 2-.34.12-.39.3-.4.61-.03.86.12 1.73-.26 2.56-.86 1.85-2.1 2.72-4.11 2.37-.75-.13-1.61-1.2-1.66-2.06-.04-.7-.06-1.4-.04-2.11.01-.46-.18-.68-.61-.71-1.13-.07-2.23-.33-3.32-.64-1.81-.52-2.78-1.81-2.45-3.92.26-1.68 1.15-2.89 2.71-3.61.77-.29 1.54-.2 2.31 0 1.74.6 3.53.72 5.35.51.24-.03.47-.17.7-.26.26-.11.18-.28.09-.46-.41-.78-1.13-1.2-1.89-1.53-1.22-.52-2.48-.94-3.71-1.46-1.83-.78-3.51-1.77-4.65-3.48-1.27-1.91-1.44-4-.9-6.17.6-2.4 1.99-4.24 4.04-5.59.64-.43 1.35-.72 2.06-1.01.2-.08.34-.16.33-.39-.01-.53.1-1.04.18-1.56.21-1.39.77-2.49 2.13-3.05.08-.03.14-.15.2-.22.77-.28 1.55-.3 2.31.02.95.51 1.31 1.38 1.43 2.39.02.15.01.3.01.45.02 1.1.583 1.68 1.69 1.74.19.01.38.02.57.04.18.19.48.04.65.24 1.16.24 2.07 1.18 2.33 2.34.52 2.29-1.03 4.24-2.35 4.86-.73.34-1.5.45-2.36.18-1.42-.45-2.91-.48-4.4-.35-.26.02-.47.16-.56.35-.08.16.15.33.28.45.77.73 1.74 1.12 2.7 1.5 2.16.87 4.4 1.57 6.15 3.23.99.94 1.66 2.08 1.94 3.43.25.26 0 .65.23.91.25 2.95-.44 5.6-2.54 7.79-.18.25-.42.44-.65.63l.01-.02Z"
      />
      <Path fill="#647A64" d="M86.71 52.54H84.4c.77-.59 1.54-.43 2.31 0Z" />
      <Path fill="#556B57" d="M92.97 27.88c-.77-.1-1.54-.04-2.31-.02.78-.52 1.55-.54 2.31.02Z" />
      <Path fill="#5F6F62" d="M101.26 49.62c-.41-.22-.19-.6-.23-.91.41.22.22.59.23.91Z" />
      <Path fill="#667966" d="M98.08 58.04c.09-.34.36-.5.65-.63-.05.38-.27.59-.65.63Z" />
      <Path fill="#607664" d="M97.32 32.73c-.24 0-.53.11-.65-.24.25 0 .54-.12.65.24Z" />
      <Path
        fill="#FDFDFD"
        d="M87.61 55.98c1.17.07 2.34.12 3.51.01 1.62-.14 3.58-1.69 3.89-3.43.39-2.15-.21-3.37-2.19-4.37-1.46-.74-3.08-1.14-4.55-1.83-1.83-.86-3.59-1.95-4.25-4.07-.66-2.11-.26-4.01 1.35-5.28.22-.17.45-.31.68-.46.37-.25.77-.43 1.17-.62.49-.38 1.11-.3 1.66-.49.87-.3 1.48-.88 1.53-1.8.04-.74.02-1.49.03-2.23 0-.35-.1-.82.32-.91.44-.1.83.18 1.11.55.18.24.26.51.26.82-.01.79 0 1.57 0 2.36 0 .58.25.88.86.93 1.14.09 2.27.24 3.38.53.38.1 1.22.92 1.17 1.24-.05.31-.33.43-.65.45-.52.03-1.01-.18-1.48-.32-1.09-.32-2.18-.2-3.27-.24-1.2-.05-2.24.36-3.13 1.16-.23.2-.47.38-.61.66-.65 1.12-1.13 2.28-.58 3.6.38.92 1.18 1.41 1.98 1.87 1.27.73 2.69 1.12 4.03 1.68 1.12.47 2.19 1.02 3.12 1.79.24.19.48.38.65.63.16.18.33.36.49.54.18.27.26.58.42.86.34.11.19.42.26.64.2.22 0 .57.24.78.05.76.06 1.53-.01 2.29-.19.18-.04.48-.24.65 0 .08-.02.16-.02.24-.2.31-.41.61-.59.93-.81 1.01-1.86 1.65-3.09 2.03-.17.2-.48.03-.64.25-.49.06-.96.22-1.43.35-1 .81-1.15 1.9-1.08 3.08.02.38.04.77 0 1.15-.03.27.23.69-.29.77-.5.07-1.15-.35-1.17-.8-.04-.89-.04-1.78-.04-2.68 0-1.13-.33-1.45-1.33-1.46-1.34-.01-2.62-.36-3.89-.71-.59-.16-1.01-.62-1.23-1.21-.06-.17-.07-.37.1-.46.27-.14.5-.35.88-.25.69.19 1.34.5 2.07.55.18.19.48.05.66.23h-.06Z"
      />
      <Path fill="#8A8C8A" d="M92.95 57.76c.41-.4.92-.38 1.43-.35-.46.17-.91.43-1.43.35Z" />
      <Path fill="#959494" d="M87.22 35.94c-.34.31-.71.55-1.17.62.3-.37.67-.63 1.17-.62Z" />
      <Path fill="#9A999D" d="M98.11 55.13c.1-.37.21-.74.59-.93-.06.4-.26.71-.59.93Z" />
      <Path fill="#999" d="M88.38 38.62c.02-.39.24-.6.61-.66-.11.31-.32.53-.61.66Z" />
      <Path fill="#8D8B8E" d="M98.98 51.02c-.39-.17-.22-.5-.24-.78.38.17.25.49.24.78Z" />
      <Path fill="#909590" d="M97.58 48.2c-.31-.11-.53-.32-.65-.63.34.08.59.26.65.63Z" />
      <Path fill="#9D9B9C" d="M98.49 49.59c-.22-.25-.44-.5-.42-.86.25.23.48.48.42.86Z" />
      <Path fill="#7A7A78" d="M87.61 55.98c-.24-.01-.55.18-.66-.23.25-.01.54-.13.66.23Z" />
      <Path fill="#9D9FA2" d="M98.73 53.96c.01-.24-.16-.55.24-.65.04.26.07.52-.24.65Z" />
      <Path fill="#8A8C8A" d="M94.38 57.41c.09-.41.4-.24.64-.25-.13.31-.37.31-.64.25Z" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h182.46v93.23H0z" />
      </ClipPath>
    </Defs>
  </Svg>
)
export default Cash
