import { OrderDeliveryDestinationType, OrderPaymentMethod } from '@prisma/client/react-native'
import { type Equals, assert } from 'tsafe'
import { z } from 'zod'
import type { AddCouponType } from '@centrito/common/constants'
import { PalommaBank } from '@centrito/api/nest/operations/payments/palomma/domain/enums'
import type {
  AlliedStorePublic,
  UserProfilePublic,
} from '@centrito/api/nest/platform/database/domain'
import type { CartPublicComposite } from '@centrito/api/nest/shopper/cart/domain/composites'
import type { UserCouponsResponse } from '@centrito/app/store/slices/userData/thunks/getUserCoupons'

/* --- STATE --- */
export const CheckoutStateSchema = z.object({
  isActionEnabled: z.boolean(),
  deliveryStepState: z.object({
    selectedType: z.nativeEnum(OrderDeliveryDestinationType).nullable(),
  }),
  paymentStepState: z.object({
    selectedMethod: z.nativeEnum(OrderPaymentMethod).nullable(),
    selectedBank: PalommaBank.nullable(),
    selectedBankName: z.string().nullable(),
  }),
})

export type CheckoutState = z.infer<typeof CheckoutStateSchema>

export const CheckoutPayloadWithPartialStepsSchema = CheckoutStateSchema.partial()

export type CheckoutPayloadWithPartialSteps = z.infer<typeof CheckoutPayloadWithPartialStepsSchema>

type DbState = {
  userProfile: UserProfilePublic | null
  cart: CartPublicComposite
  selectedStore: AlliedStorePublic | null
}

export type SessionProduct = {
  id: string
  timestamp: number // Unix timestamp in milliseconds
  scrollCount: number
}

export const SessionProductSchema = z.object({
  id: z.string(),
  timestamp: z.number(), // Unix timestamp in milliseconds
  scrollCount: z.number(),
})

export type AppDataState = Omit<DbState, keyof DbState> & {
  checkout: CheckoutState
  searchedTerms: string[]
  anonymousCartId: string | null
  anonymousUserId: string | null
  sessionProductIds: SessionProduct[]
  isEmailNotificationsEnabled: boolean
}

export const UserDataState = z.object({
  // Db state
  userProfile: z.any(),
  cart: z.any(),
  selectedStore: z.any(),
  // App state
  checkout: CheckoutStateSchema,
  searchedTerms: z.array(z.string()),
  anonymousCartId: z.string().nullable(),
  anonymousUserId: z.string().nullable(),
  sessionProductIds: z.array(SessionProductSchema),
  isEmailNotificationsEnabled: z.boolean(),
})

export type UserDataState = {
  userProfile: UserProfilePublic | null
  cart: CartPublicComposite
  selectedStore: AlliedStorePublic | null
  checkout: CheckoutState
  searchedTerms: string[]
  anonymousCartId: string | null
  anonymousUserId: string | null
  sessionProductIds: SessionProduct[]
  isEmailNotificationsEnabled: boolean
  userCoupons: UserCouponsResponse | null
}

assert<
  Equals<
    Omit<AppDataState, 'userProfile' | 'cart' | 'selectedStore'>,
    Omit<z.infer<typeof UserDataState>, 'userProfile' | 'cart' | 'selectedStore'>
  >
>()

export type AddCouponPayload =
  | {
      type: AddCouponType.ID
      couponId: string
    }
  | {
      type: AddCouponType.CODE
      couponCode: string
    }
  | {
      type: AddCouponType.USER_PROMOTION
      userPromotionId: string
    }
