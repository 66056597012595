import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import type { CategoryPublic } from '@centrito/api/nest/platform/database/domain'
import {
  GetProductsFilterType as FilterType,
  GetProductsFilterType,
  ProductSortType,
} from '@centrito/api/shared/enums'
import { trpc } from '@centrito/app/api/trpc'
import { PRICE_RANGE_DEFAULT } from '@centrito/app/components/Feed/FilterBar/Overlay/context/constants'
import useChangeOptionsPriceRange from '@centrito/app/components/Feed/FilterBar/Overlay/context/useChangeOptionsPriceRange'
import { useFiltersQuery } from '@centrito/app/utils/hooks/useFeedProducts/useFiltersQuery'
import { FilterOverlay, initialContext } from './types'
import useApplyFilters from './useApplyFilters'
import useChangeOptionItemIsChecked from './useChangeOptionItemIsChecked'
import useClearOptions from './useClearOptions'
import { useRelevantFilterDependencies } from './useRelevantFilterDependencies'
import useResetOptions from './useResetOptions'

export const FilterOverlayContext = createContext<FilterOverlay.Context>(initialContext)

const generateFilterObject = (): { [key in GetProductsFilterType]: undefined } => {
  const filterObject = {} as { [key in GetProductsFilterType]: undefined }

  Object.keys(FilterOverlay.OverlayFilterEnum)
    .filter((key) => isNaN(Number(key)))
    .forEach((key) => {
      const filterKey = GetProductsFilterType[key as keyof typeof GetProductsFilterType]
      filterObject[filterKey] = undefined
    })

  return filterObject
}

const DEFAULT_OPTIONS = generateFilterObject()

interface FilterOverlayProviderProps {
  categoryNodesPrefix: string
  brandId: string
  children: React.ReactNode
}

const FilterOverlayProvider: React.FC<FilterOverlayProviderProps> = ({
  children,
  brandId,
  categoryNodesPrefix,
}) => {
  const { filters } = useFiltersQuery()
  const isBrandFilter = !isEmpty(brandId)
  const categoryPrefix =
    (FilterType.CATEGORY_PREFIX in filters && filters[FilterType.CATEGORY_PREFIX]) || ''
  const [isVisibleOverlay, setIsVisibleOverlay] = useState<boolean>(false)
  const { data: _categories, isFetching: isFetchingCategories } =
    trpc.catalog.category.findMany.useQuery()

  const { data: dataBrandCategories, isFetching: isFetchingBrandCategories } =
    trpc.catalog.brand.findBrandCategories.useQuery(
      {
        brandId,
      },
      { enabled: !isNil(brandId) && !isEmpty(brandId) },
    )

  const [options, setOptions] = useState<FilterOverlay.Options>(DEFAULT_OPTIONS)
  const [currentNode, setCurrentNode] = useState<string>(categoryNodesPrefix)
  const [priceRangeBoundaries, setPriceRangeBoundaries] = useState<{ low: number; high: number }>({
    low: PRICE_RANGE_DEFAULT.low,
    high: PRICE_RANGE_DEFAULT.high,
  })
  const [priceRange, setPriceRange] = useState<number[]>([
    priceRangeBoundaries.low,
    priceRangeBoundaries.high,
  ])
  const [categories, setCategories] = useState<CategoryPublic[]>([])
  const [categoriesDepth, setCategoriesDepth] = useState<number>(1)
  const [currentFilterDrawer, setCurrentFilterDrawer] = useState<
    FilterOverlay.OverlayFilterType | undefined
  >(undefined)
  const { changeOptionItemIsChecked } = useChangeOptionItemIsChecked(options, setOptions)
  const { changePriceFilters } = useChangeOptionsPriceRange(options, setOptions)
  const { clearOptions: _clearOptions } = useClearOptions(setOptions, options, currentFilterDrawer)
  const { resetOptions } = useResetOptions(setOptions)
  const { applyFilters: _applyFilters } = useApplyFilters(
    options,
    setIsVisibleOverlay,
    isVisibleOverlay,
    resetOptions,
  )

  const { relevantPriceRange } = useRelevantFilterDependencies({
    filters,
    priceRangeBoundaries,
    setOptions,
  })

  const [sortType, setSortType] = useState<ProductSortType>(ProductSortType.NONE)

  const clearOptions = useCallback((): void => {
    _clearOptions()
    setPriceRange([priceRangeBoundaries.low, priceRangeBoundaries.high])
  }, [_clearOptions, priceRangeBoundaries])

  const applyFilters = useCallback((): void => {
    _applyFilters({
      low: priceRange[0],
      high: priceRange[1],
      boundaryLow: priceRangeBoundaries.low,
      boundaryHigh: priceRangeBoundaries.high,
    })
  }, [_applyFilters, priceRange, priceRangeBoundaries])

  useEffect(() => {
    setCurrentNode(decodeURIComponent(categoryNodesPrefix))
  }, [categoryNodesPrefix])

  useEffect(() => {
    if (!isNil(dataBrandCategories) && !isNil(dataBrandCategories.categories)) {
      const currentState = { currentCategories: dataBrandCategories.categories }
      if (!isNil(currentNode) && !isEmpty(currentNode)) {
        currentState.currentCategories = currentState.currentCategories.filter((brandCategory) =>
          brandCategory.nodes.startsWith(currentNode),
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataBrandCategories, currentNode])

  useEffect(() => {
    const allCategories = !isNil(dataBrandCategories) ? dataBrandCategories.categories : _categories
    if (
      !isNil(allCategories) &&
      !isNil(currentNode) &&
      !isEmpty(currentNode) &&
      !isFetchingBrandCategories
    ) {
      const filteredCategories = allCategories.filter(
        (item) =>
          item.nodes.startsWith(currentNode) &&
          item.nodes.split('|').length - 1 === categoriesDepth,
      )
      const newDepth = currentNode.split('|').length
      setCategoriesDepth(newDepth)
      // if there is only one categoty left, then it is not necessary to show the filter
      setCategories(filteredCategories.length > 1 ? filteredCategories : [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_categories, categoriesDepth, currentNode, dataBrandCategories])

  useEffect(() => {
    if (
      !isNil(relevantPriceRange) &&
      (priceRangeBoundaries.low !== relevantPriceRange.low ||
        priceRangeBoundaries.high !== relevantPriceRange.high)
    ) {
      const newBoundaries = relevantPriceRange
      setPriceRangeBoundaries(newBoundaries)
      if (priceRange[0] !== relevantPriceRange.low || priceRange[1] !== relevantPriceRange.high) {
        setPriceRange([newBoundaries.low, newBoundaries.high])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relevantPriceRange])

  useEffect(() => {
    const priceOption = options[GetProductsFilterType.PRICE_RANGE]?.pop()?._filterItem.value

    if (
      !isNil(priceOption) &&
      (priceOption.low !== priceRange[0] || priceOption.high !== priceRange[1])
    ) {
      changePriceFilters(priceRange[0], priceRange[1])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options[GetProductsFilterType.PRICE_RANGE]])

  const value = useMemo(
    () => ({
      categories,
      options,
      isVisibleOverlay,
      currentFilterDrawer,
      setCurrentFilterDrawer,
      currentNode,
      isBrandFilter,
      brandId,
      currentNodeDepth: categoriesDepth,
      categoryPrefix,
      priceRangeBoundaries,
      priceRange,
      setPriceRange,
      isFetchingCategories,
      setIsVisibleOverlay,
      changeOptionItemIsChecked,
      applyFilters,
      clearOptions,
      resetOptions,
      sortType,
      setSortType,
    }),
    [
      categories,
      options,
      isVisibleOverlay,
      currentFilterDrawer,
      currentNode,
      isBrandFilter,
      brandId,
      categoriesDepth,
      categoryPrefix,
      priceRangeBoundaries,
      priceRange,
      setPriceRange,
      isFetchingCategories,
      changeOptionItemIsChecked,
      applyFilters,
      clearOptions,
      resetOptions,
      sortType,
      setSortType,
    ],
  )

  return <FilterOverlayContext.Provider value={value}>{children}</FilterOverlayContext.Provider>
}
export default FilterOverlayProvider
