import type { GetStaticProps, NextApiRequest, NextApiResponse } from 'next'
import { createServerSideHelpers } from '@trpc/react-query/server'
import { type ParsedUrlQuery } from 'querystring'
import superjson from 'superjson'
import { appRouter, createContext } from '@centrito/api'
import { REVALIDATE_HOME_PAGE_INTERVAL } from '@centrito/api/config'
import { isDev } from '@centrito/app/config'
import HomeScreen from '@centrito/app/features/shopper/home/screen'

export async function getStaticPaths(): Promise<{ paths: string[]; fallback: string | boolean }> {
  return {
    paths: [],
    fallback: isDev ? 'blocking' : true,
  }
}

interface Params extends ParsedUrlQuery {
  tab: string
}

export const getStaticProps: GetStaticProps<object, Params> = async (context) => {
  const tab = context.params?.tab as string
  const ssg = createServerSideHelpers({
    router: appRouter,
    ctx: await createContext({
      req: undefined as any as NextApiRequest,
      res: undefined as any as NextApiResponse,
    }),
    transformer: superjson,
  })

  await Promise.all([
    ssg.catalog.category.findMany.prefetch(),
    ssg.catalog.homeBanner.findMany.prefetch(),
  ])
  return {
    props: {
      SEOProperties: {
        urlPath: `/home/${tab}`,
      },
      trpcState: ssg.dehydrate(),
    },
    revalidate: REVALIDATE_HOME_PAGE_INTERVAL,
  }
}

export default HomeScreen
