import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import type { $Enums } from '@centrito/db'
import type { RouterOutputs } from '@centrito/app/api/trpc'
import trpcProxyClient from '@centrito/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'
import { getCartOwner } from '@centrito/app/store/slices/userData/utils/getCartOwner'

export type UserCouponsResponse = RouterOutputs['promotions']['coupons']['getUserCoupons']

const getUserCoupons = createAsyncThunk<
  UserCouponsResponse,
  {
    state: $Enums.UserPromotionStatus
  },
  AppThunkApiConfig
>(`${REDUCER_NAMES.USER_DATA}/getUserCoupons`, async (payload, thunkAPI) => {
  try {
    const currentState = thunkAPI.getState()
    const cartOwner = getCartOwner(currentState, thunkAPI.dispatch)

    return await trpcProxyClient.promotions.coupons.getUserCoupons.query({
      cartOwner,
      state: payload.state,
    })
  } catch (error) {
    return thunkAPI.rejectWithValue(miniSerializeError(error))
  }
})

export default getUserCoupons
