import React, { type SVGProps } from 'react'

export const NequiMinimalist: React.FC = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="22"
    fill="none"
    viewBox="0 0 21 22"
  >
    <path
      fill="#FD2B72"
      fillRule="evenodd"
      d="M6.313 5.887l-1.896.979c-.844.435-1.311 1.85-.781 2.574l.045.062 1.79-2.457.842-1.158z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#2FC8D6"
      fillRule="evenodd"
      d="M6.309 13.097L3.68 9.502l-.524.72c-.53.73.06 2.162.779 2.524l3.381 1.706c-.346-.46-.685-.912-1.008-1.355z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#FD2B72"
      fillRule="evenodd"
      d="M12.456 17.044l-4.55-2.294-.589-.297c.738.979 1.51 1.987 2.245 2.992.646.884 1.697.89 2.425.105l.469-.506z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#2FC8D6"
      fillRule="evenodd"
      d="M15.177 14.108l-2.72 2.935.452.23c.87.438 1.8.042 2.05-.847l.835-2.983-.617.665z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#FD2B72"
      fillRule="evenodd"
      d="M16.246 11.826l-.453 1.618 1.874-2.022c.423-.457.564-1.036.047-1.617l-.294-.33-.393-.442-.78 2.793z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#2FC8D6"
      fillRule="evenodd"
      d="M14.934 6.678l2.094 2.354.218-.777c.143-.513-.008-1.025-.652-1.284l-.422-.17L14.42 6.1l.514.578z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#FD2B72"
      fillRule="evenodd"
      d="M12.567 5.358l1.853.742-1.822-2.048-.266-.3c-.447-.503-1.003-.455-1.52-.188l-1.188.613 2.943 1.18z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#200049"
      fillRule="evenodd"
      d="M14.42 6.1l-1.853-.742-2.943-1.18-1.819.939-1.492.77-.842 1.159-1.79 2.457 2.628 3.595c.324.443.662.896 1.009 1.355l.588.297 4.55 2.294 2.72-2.935.617-.665.454-1.619.781-2.792-2.094-2.354-.514-.578z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#2FC8D6"
      fillRule="evenodd"
      d="M7.805 5.117l1.818-.94-.584-.234-.15-.06c-.56-.224-.982-.187-1.276.217l-.118.162-1.183 1.626 1.493-.771z"
      clipRule="evenodd"
    ></path>
  </svg>
)
