import React from 'react'
import { Link } from 'solito/link'
import PATHS from '@centrito/common/paths'
import { OUTLETS } from '@centrito/app/utils/helpers'
import { Circle, XStack } from '@centrito/ui/src'

export const OutletsList: React.FC = () => {
  return (
    <XStack
      flexWrap="wrap"
      gap={10}
      paddingHorizontal={10}
      paddingBottom={10}
      $platform-web={{
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
      }}
    >
      {OUTLETS.filter((outlet) => outlet.isActive).map((outlet) => (
        <Link
          key={outlet.id}
          href={{
            pathname: PATHS.Products.Outlets,
            query: {
              outletId: outlet.id,
            },
          }}
        >
          <Circle
            aspectRatio={1}
            backgroundColor="$pureWhite"
            padding={15}
            borderWidth={1}
            borderColor="$neutral200"
          >
            <outlet.logo />
          </Circle>
        </Link>
      ))}
    </XStack>
  )
}
