import { type ProductPresentationState } from './types'

export const productPresentationInitialState: ProductPresentationState = {
  presentations: [],
  presentationsWithQuantity: [],
  selectedPresentation: undefined,
  presentationIndex: 0,
  showValidation: false,
  userSelectColor: false,
  isScrollToValidation: false,
  productImageHeight: 0,
}
