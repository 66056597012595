import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import isNil from 'lodash.isnil'
import type { MerchantPublic } from '@centrito/api/nest/platform/database/domain'
import trpcProxyClient from '@centrito/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'

export interface LoadMerchantDataResponse {
  merchantProfile: MerchantPublic | null
  phone: string | null
  name: string
}

export default createAsyncThunk<LoadMerchantDataResponse, void, AppThunkApiConfig>(
  `${REDUCER_NAMES.MERCHANT_DATA}/loadMerchantData`,
  async (_, thunkAPI) => {
    try {
      const merchantProfile = await trpcProxyClient.merchant.profile.getProfile.query()

      if (isNil(merchantProfile)) {
        throw new Error('Merchant profile not found')
      }
      return {
        merchantProfile,
        phone: isNil(merchantProfile.supplyContactPhones)
          ? merchantProfile.contactPhone
          : merchantProfile.supplyContactPhones[0],
        name: merchantProfile.name,
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(miniSerializeError(error))
    }
  },
)
