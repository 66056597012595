import React, { createContext, useEffect, useMemo, useState } from 'react'
import type { CategoryPublic } from '@centrito/api/nest/platform/database/domain'
import { trpc } from '@centrito/app/api/trpc'
import { subhomeScreenContext } from './initialContextState'
import { SubhomeKey, type SubhomeScreenContextType, TabBarOptions } from './types'

export const SubhomeScreenContext = createContext<SubhomeScreenContextType>(subhomeScreenContext)

export interface SubhomeScreenProviderProps {
  children: React.ReactNode
}

export const SubhomeScreenProvider: React.FC<SubhomeScreenProviderProps> = ({ children }) => {
  const [currentScreen, setCurrentScreen] = useState<SubhomeKey>(SubhomeKey.MAIN)
  const [currentTabBarOption, setCurrentTabBarOption] = useState<TabBarOptions>(
    TabBarOptions.EXPLORE,
  )
  const changeScreen = (newScreen: SubhomeKey): void => {
    setCurrentScreen(newScreen)
  }
  const updateTabBarOption = (newTabBarOption: TabBarOptions): void => {
    setCurrentTabBarOption(newTabBarOption)
  }

  const [currentPages, setCurrentPages] = useState<{ [key: string]: number }>({ '/': 0 })
  const updateCurrentPages = (key: string, value: number): void => {
    setCurrentPages((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }

  const [isTabBarSticky, setIsTabBarSticky] = useState<boolean>(false)
  const updateIsTabBarSticky = (isSticky: boolean): void => {
    setIsTabBarSticky(isSticky)
  }

  const [categories, setCategories] = useState<{ [key: string]: CategoryPublic[] }>({})

  const { data: _categories } = trpc.catalog.category.findMany.useQuery(undefined)

  const homeCategoryNodes: string[] = [
    // First row
    'ESCOLAR',
    'ESCOLAR|ESCRITURA',
    'BELLEZA',
    'MUJER',
    'COCINA',
    'ACCESORIOS_DE_BELLEZA',
    'MASCOTAS',
    'NIÑO',
    'HOGAR',
    'JOYERÍA_Y_ACCESORIOS',
    'HOMBRE',
    'ACCESORIOS_ELECTRÓNICOS',
    'MATERNIDAD_Y_BEBÉS',
    'JUGUETES',
    'NIÑA',
    'ADULTOS',
    'PIÑATERIA',
    'ACCESORIOS_DEPORTIVOS',
    'ASEO',
    'SUPLEMENTOS_DEPORTIVOS',
    'CALZADO',
    // Second row
    'ESCOLAR|MALETAS_Y_ACCESORIOS',
    'ESCOLAR|PAPELERÍA',
    'ESCOLAR|ÚTILES',
    'BELLEZA|MAQUILLAJE',
    'BELLEZA|CUIDADO_CAPILAR',
    'ACCESORIOS_DE_BELLEZA|CABELLO',
    'PAPELERÍA|ESCRITURA',
    'MASCOTAS|PERROS',
    'MUJER|BLUSAS',
    'COCINA|PREPARACIÓN_Y_MEZCLA',
    'MUJER|BODYS',
    'JOYERÍA_Y_ACCESORIOS|DE_MUJER',
    'HOGAR|ORGANIZACIÓN',
    'ACCESORIOS_DE_BELLEZA|FACIAL',
    'COCINA|ORGANIZADORES',
    'MASCOTAS|GATOS',
    'NIÑO|CONJUNTOS',
    'ACCESORIOS_ELECTRÓNICOS|DISPOSITIVOS_MÓVILES',
    'MATERNIDAD_Y_BEBÉS|ALIMENTACIÓN',
    'JUGUETES|EXTERIORES',
    'HOMBRE|CAMISETAS',
    'NIÑA|CONJUNTOS',
    'ADULTOS|ESTIMULANTES',
  ]

  useEffect(() => {
    if (_categories && Object.keys(categories).length === 0) {
      const newCategoriesObj: { [key: string]: CategoryPublic[] } = {}
      newCategoriesObj['/'] = []
      _categories.forEach((item) => {
        const key = item.nodes.split('|')[0]
        const rest = item.nodes.split('|').slice(1)
        if (rest.length === 1) {
          if (!newCategoriesObj[key]) {
            newCategoriesObj[key] = []
          }

          newCategoriesObj[key].push(item)
        } else if (rest.length === 0) {
          updateCurrentPages(key, 0)
        }
        if (homeCategoryNodes.includes(item.nodes)) {
          newCategoriesObj['/'].push(item)
        }
      })
      newCategoriesObj['/'].sort(
        (a, b) => homeCategoryNodes.indexOf(a.nodes) - homeCategoryNodes.indexOf(b.nodes),
      )

      setCategories(newCategoriesObj)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_categories])

  const value = useMemo(
    () => ({
      categories,
      currentScreen,
      changeScreen,
      currentTabBarOption,
      updateTabBarOption,
      currentPages,
      updateCurrentPages,
      isTabBarSticky,
      updateIsTabBarSticky,
    }),
    [categories, currentPages, currentScreen, currentTabBarOption, isTabBarSticky],
  )

  return <SubhomeScreenContext.Provider value={value}>{children}</SubhomeScreenContext.Provider>
}
