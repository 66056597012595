export enum DisplayType {
  CIRCLE = 'CIRCLE',
  SMALL_RECTANGLE = 'SMALL_RECTANGLE',
  LARGE_RECTANGLE = 'LARGE_RECTANGLE',
}

export enum IconVariant {
  ARROW = 'ARROW',
  RETRY = 'RETRY',
  CART = 'CART',
}
