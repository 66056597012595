import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { priceChopJourneyInitialState } from './initialState'
import { type PriceChopJourneyStore } from './types'

export const usePriceChopJourneyStore = create<PriceChopJourneyStore>()(
  immer((set) => ({
    ...priceChopJourneyInitialState,
    setProductId: (productId): void => set(() => ({ productId })),
    setVariantId: (variantId): void => set(() => ({ variantId })),
    setIsProductRedeemed: (isProductRedeemed): void => set(() => ({ isProductRedeemed })),
  })),
)
