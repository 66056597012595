import React, { useEffect, useState } from 'react'
import isNil from 'lodash.isnil'
import { Linking } from 'react-native'
import posthogClient from '@centrito/app/utils/services/analytics/posthog'
import { XStack, YStack } from '@centrito/ui/src'
import WhatsAppIcon2 from '@centrito/ui/src/components/svgs/logos/WhatsAppV2'
import { ButtonText } from '@centrito/ui/src/components/text/new'
import { HELP_BUTTON_WHATSAPP_NUMBER } from '@centrito/ui/src/config'

interface WhatsAppButtonProps {
  eventProperties?: {
    productId: string
    productName: string
    merchantId: string
    categoryId: string
  }
}

export const WhatsAppButton: React.FC<WhatsAppButtonProps> = ({ eventProperties }) => {
  const [isRevealed, setIsRevealed] = useState<boolean>(false)
  const WA_LINK = `https://wa.me/${HELP_BUTTON_WHATSAPP_NUMBER}?text=Hola,%20necesito%20ayuda%20con%20la%20app%20de%20*Samsam*%20`

  useEffect(() => {
    if (isRevealed) {
      const timer = setTimeout(() => {
        setIsRevealed(false)
      }, 4000)
      return () => clearTimeout(timer)
    }
    // Return a no-op function if isRevealed is false
    return () => {}
  }, [isRevealed])

  const handlePress = async (): Promise<void> => {
    if (!isRevealed) {
      setIsRevealed(true)
      if (!isNil(eventProperties)) {
        posthogClient.captureCustomEvent('shopper_product_page_whatsapp_reveal', eventProperties)
      }
    } else {
      await Linking.openURL(WA_LINK)
      setIsRevealed(false)
      if (!isNil(eventProperties)) {
        posthogClient.captureCustomEvent('shopper_product_page_whatsapp_open', eventProperties)
      }
    }
  }

  return (
    <XStack
      backgroundColor="#32BB46"
      alignItems="center"
      borderTopLeftRadius={40}
      borderBottomLeftRadius={40}
      borderTopRightRadius={0}
      borderBottomRightRadius={0}
      hoverStyle={{ backgroundColor: '#25a13d', cursor: 'pointer' }}
      height={45}
      onPress={handlePress}
      paddingVertical={10}
      paddingHorizontal={15}
      gap={5}
    >
      <XStack width={30} height={30}>
        <WhatsAppIcon2 />
      </XStack>
      {isRevealed && (
        <YStack paddingRight={50}>
          <ButtonText color="white">¿Necesitas ayuda?</ButtonText>
        </YStack>
      )}
    </XStack>
  )
}
