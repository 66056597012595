import React, { useContext } from 'react'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import { createParam } from 'solito'
import type { BrandPublic } from '@centrito/api/nest/platform/database/domain/entities/brand'
import DefaultCategoriesBar from '@centrito/app/components/Feed/FilterBar/DefaultCategoriesBar'
import MerchantCard from '@centrito/app/components/Feed/FilterBar/MerchantCard'
import { FilterOverlayContext } from '@centrito/app/components/Feed/FilterBar/Overlay/context'
import ProductListCategoriesBar from '@centrito/app/components/Feed/FilterBar/ProductList/ProductListCategoriesBar'
import { type MerchantInfo } from '@centrito/app/components/Feed/FilterBar/Ribbon'
import { XStack } from '@centrito/ui/src'
import BaseGrid from '@centrito/ui/src/components/BaseGrid'
import { LOGO_SIZES } from '@centrito/ui/src/components/outlet'
import { OUTLETS_LOGOS } from '@centrito/ui/src/components/svgs/outlets/logos'
import { BodyText1 } from '@centrito/ui/src/components/text/new/body/BodyText1'
import { SubtitleText1 } from '@centrito/ui/src/components/text/new/subtitles/SubtitleText1'

interface CategoryTreeProps {
  isLoading: boolean
  searchTerm?: string
  count?: number
  merchantInfo?: MerchantInfo
  brandInfo?: BrandPublic
}

type Params = {
  listId?: string
}

const { useParams } = createParam<Params>()

const CategoryTree: React.FC<CategoryTreeProps> = ({
  count,
  searchTerm,
  isLoading,
  merchantInfo,
  brandInfo,
}) => {
  const { params } = useParams()
  const isProductList = !isNil(params?.listId)
  const { currentNode } = useContext(FilterOverlayContext)

  const currentNodeText = isNil(currentNode) ? '' : currentNode.split('|').pop()?.replace(/_/g, ' ')
  const pageTitle = !isNil(searchTerm) ? searchTerm : currentNodeText

  return (
    <BaseGrid
      stackProps={{
        backgroundColor: '$pureWhite',
        width: '100%',
        borderBottomColor: '#f2f2f2',
        borderBottomWidth: 8,
        paddingVertical: 10,
        paddingHorizontal: 5,
        marginTop: 5,
      }}
    >
      {!isNil(merchantInfo) && !isNil(merchantInfo?.name) && !isEmpty(merchantInfo?.name) && (
        <BaseGrid.Row stackProps={{ width: '100%' }}>
          <BaseGrid.Column stackProps={{}}>
            <MerchantCard name={merchantInfo.name} orderCount={merchantInfo.orderCount} />
          </BaseGrid.Column>
        </BaseGrid.Row>
      )}
      <BaseGrid.Row stackProps={{ paddingHorizontal: 8 }}>
        <BaseGrid.Column
          stackProps={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingBottom: 8,
          }}
        >
          {!isNil(pageTitle) &&
            pageTitle !== 'undefined' &&
            !isNil(brandInfo) &&
            !isNil(brandInfo?.name) && (
              <XStack gap={3}>
                <SubtitleText1>{brandInfo.name}</SubtitleText1>
                <BodyText1 color="$Gray2" fontWeight="600" alignSelf="flex-end">
                  {`${pageTitle.slice(0, 1)}${pageTitle.slice(1).toLowerCase()}`}
                </BodyText1>
              </XStack>
            )}

          {!isNil(pageTitle) && pageTitle !== 'undefined' && isNil(brandInfo) && (
            <SubtitleText1>
              {`${pageTitle.slice(0, 1)}${pageTitle.slice(1).toLowerCase()}`}
            </SubtitleText1>
          )}

          {!isNil(brandInfo) &&
            !isNil(brandInfo?.name) &&
            (isNil(pageTitle) || pageTitle === 'undefined') &&
            (!isNil(OUTLETS_LOGOS[brandInfo.name.toUpperCase() as keyof typeof OUTLETS_LOGOS]) ? (
              OUTLETS_LOGOS[brandInfo.name.toUpperCase() as keyof typeof OUTLETS_LOGOS](
                LOGO_SIZES[brandInfo.name.toUpperCase() as keyof typeof LOGO_SIZES]?.big,
              )
            ) : (
              <SubtitleText1>{brandInfo.name}</SubtitleText1>
            ))}

          <BodyText1 color="$Gray2" alignContent="flex-end">
            {count && !isLoading ? `${count} artículo${count === 1 ? '' : 's'}` : '...'}
          </BodyText1>
        </BaseGrid.Column>
      </BaseGrid.Row>
      <BaseGrid.Row>
        <BaseGrid.Column>
          {isProductList ? <ProductListCategoriesBar /> : <DefaultCategoriesBar />}
        </BaseGrid.Column>
      </BaseGrid.Row>
    </BaseGrid>
  )
}
export default CategoryTree
