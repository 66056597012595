import React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'solito/router'
import { useCanGoBack } from '@centrito/app/components/BackButton/useCanGoBack'
import { WhatsAppButton } from '@centrito/app/components/WhatsAppButton'
import { Stack, YStack } from '@centrito/ui/src'
import { PrimaryButton } from '@centrito/ui/src/components/button'
import { SadSamSamLogo } from '@centrito/ui/src/components/svgs/logos/SadSamSamLogo'
import { ContentStandardText } from '@centrito/ui/src/components/text/new'

interface FallbackProps {
  hasWhatsAppButton?: boolean
}

export const Fallback: React.FC<FallbackProps> = ({ hasWhatsAppButton = false }) => {
  const router = useRouter()
  const canGoBack = useCanGoBack()
  const queryClient = useQueryClient()

  const reloadApp = async (): Promise<void> => {
    queryClient.invalidateQueries()
    queryClient.removeQueries()
    if (canGoBack) {
      router.back()
    } else {
      router.push('/')
    }
  }

  return (
    <YStack width="100%" flexGrow={1} alignItems="center" justifyContent="center" gap="$10">
      <YStack width="80%" gap="$5" alignItems="center">
        <SadSamSamLogo />
        <YStack alignItems="center">
          <ContentStandardText textAlign="center">Ups. Algo ha salido mal!</ContentStandardText>
          <ContentStandardText>Intenta mas tarde</ContentStandardText>
        </YStack>
      </YStack>
      <YStack gap="$2" width="100%" alignItems="center">
        <PrimaryButton onPress={reloadApp}>Intenta nuevamente</PrimaryButton>
      </YStack>
      {hasWhatsAppButton && (
        <Stack zIndex={999} position="absolute" top="55%" right={0}>
          <WhatsAppButton />
        </Stack>
      )}
    </YStack>
  )
}
