export const getCurrentVersionInfo = async (): Promise<{
  isCurrentVersion: boolean
  updateChannel: string
  nativeAppVersion: string | null
  nativeBuildVersion: string | null
}> => {
  return {
    isCurrentVersion: true,
    updateChannel: '',
    nativeAppVersion: '',
    nativeBuildVersion: '',
  }
}
