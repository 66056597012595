import React from 'react'
import Svg, { G, Path, type SvgProps } from 'react-native-svg'

const SamsamWinkingLogo: React.FC<SvgProps> = (props) => (
  <Svg viewBox="0 0 64.53 52" {...props}>
    <G data-name="Capa 1">
      <Path
        fill="none"
        stroke="#546ee5"
        strokeWidth={1.5}
        d="M.76 38.45H.75v-16.6C.75 9.83 10.83.1 22.79.79a21.47 21.47 0 0 1 5.59 1.09 13.52 13.52 0 0 0 7.52.07c.39-.09.74-.22 1.04-.33a20.87 20.87 0 0 1 26.82 19.99v17.67a12.61 12.61 0 0 1-21.48 8.39l-.22-.22h-.65l-.22.21c-2.28 2.2-5.33 3.54-8.76 3.54s-6.64-1.42-8.91-3.69l-.22-.22h-.67l-.22.23A12.58 12.58 0 0 1 .78 39.28l-.03-.72Z"
      />
      <Path
        fill="#546ee5"
        d="M42.05 25.15a6.58 6.58 0 0 1-1.85-3.69c-.13-.78.08-1.17.71-1.27.63-.1.94.17 1.1.95.5 2.38 2.05 3.67 4.16 3.46a4.54 4.54 0 0 0 4.1-3.49l.13-.41c.39-.37.83-.52 1.3-.23.49.31.44.83.32 1.3a5.86 5.86 0 0 1-2.4 3.46c.6.28 1.19.3 1.74.42.76.17 1.14.62 1.02 1.2-.11.51-.66.8-1.38.63a25.21 25.21 0 0 0-10.84-.16c-.53.11-.96.02-1.22-.48-.29-.56.05-1.17.75-1.31.73-.15 1.48-.25 2.37-.4ZM28 25.56c.53.07.86.32 1.02.86.28.92.9 1.42 1.59 1.4.71-.03 1.3-.56 1.58-1.44.32-1 .52-1.11 1.53-.88.29.26.39.6.35.99a3.64 3.64 0 0 1-3.19 3.14 3.41 3.41 0 0 1-3.62-2.56c-.2-.69-.11-1.24.73-1.52Zm27.66.76c-.36-1.08-.1-1.48.91-1.49 1.24-.01 2.47.17 3.69.36.57.09.81.49.74 1.02-.06.49-.42.75-.9.68a24.8 24.8 0 0 0-3.85-.33c-.19 0-.39-.15-.59-.24Zm4.25-5.91c.01.46-.22.78-.64.89-1.03.3-2.05.67-3.04 1.07-.53.21-.99.18-1.27-.39-.22-.47.04-.94.65-1.22 1.01-.46 2.05-.81 3.11-1.14.64-.19 1.16.18 1.19.78Zm-42.93-3.59a4.27 4.27 0 0 0-4.27 4.27v3.11c0 .8.23 1.55.61 2.19.23-.15.52-.24.86-.25 2.14-.01 4.3-.16 6.36.38.44-.67.69-1.47.69-2.33v-3.11a4.27 4.27 0 0 0-4.26-4.27Zm-.2 4.6c0 .72-.59 1.31-1.31 1.31s-1.31-.59-1.31-1.31v-1.09c0-.72.59-1.31 1.31-1.31s1.31.59 1.31 1.31v1.09Z"
      />
      <Path
        fill="#546ee5"
        d="M13.22 25.52c-.88.14-1.63.25-2.37.4-.69.14-1.04.76-.75 1.31.26.5.7.59 1.22.48 1.66-.35 3.35-.5 5.05-.5 1.95 0 3.88.2 5.79.66.72.17 1.27-.11 1.38-.63.13-.58-.26-1.04-1.02-1.2-.56-.12-1.14-.14-1.74-.42a24.27 24.27 0 0 0-7.56-.1Z"
      />
    </G>
  </Svg>
)
export default SamsamWinkingLogo
