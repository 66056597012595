import React from 'react'
import Svg, { Path, type SvgProps } from 'react-native-svg'

const ToySmartLogo: React.FC<SvgProps> = (props) => (
  <Svg viewBox="0 0 60.9 15.19" {...props}>
    <Path
      fill="#5e4796"
      d="M29.01 11.15c-2.02 2.27-5.35 1.42-7.15.37-.22-.13-.25-.25-.13-.47.37-.72.73-1.44 1.07-2.18.16-.35.32-.31.58-.12.51.37 1.08.66 1.69.83.31.09.62.11.94.08.25-.02.5-.09.55-.36.05-.29-.24-.33-.42-.4-.75-.31-1.48-.63-2.13-1.14-.64-.5-1.13-1.1-1.39-1.87-.15-.06-.19-.2-.19-.34-.02-.89 0-1.77.74-2.43.65-.79 1.48-1.29 2.5-1.43.31-.04.37-.2.37-.48 0-.33-.17-.35-.42-.31-.28.04-.33-.12-.33-.35 0-.22-.06-.45.28-.47.04 0 .08-.01.12-.01 1.38-.04 1.12-.36 1.16.99 0 .38.05.59.5.57.29-.01.59.06.87.14.29.08.38-.09.51-.27.17-.24.02-.36-.14-.48-.28-.19-.41-.35-.11-.69.32-.37.46-.07.66.07.08.06.14.14.23.19.55.3.65.66.2 1.14-.24.25-.18.4.14.55 1.18.57 1.17.58.56 1.72 0 .02-.02.03-.03.05-.25.45-.4 1.06-.78 1.3-.45.29-.84-.37-1.31-.5-.43-.12-.84-.2-1.28-.14-.21.03-.37.14-.44.34-.08.23.04.39.21.5.26.18.52.35.8.5.98.53 1.79 1.21 2.25 2.26.25.22.31.52.26.81-.12.75-.22 1.53-.91 2.03Z"
    />
    <Path
      fill="#3cbac6"
      d="M29.01 11.15c.58-.86.92-1.78.65-2.84l2.38-6.38c.77.89 1.48 1.72 2.2 2.54.05.06.1.13.15.19q.47.57 1.01.04c.61-.59 1.23-1.18 1.82-1.78.28-.28.39-.22.5.12.5 1.48 1.02 2.95 1.54 4.42.06.18.15.36.23.54.2.11.11.28.06.41-.35.86-.6 1.75-1.03 2.57-.28.53.31.35.44.54.04.05.05.1.04.15q-1.41.3-1.9-1.08c-.22-.61-.44-1.23-.67-1.84-.01-.04-.04-.07-.11-.19-.42.68-.84 1.31-1.21 1.97-.25.45-.4.4-.66 0-.46-.71-.97-1.38-1.52-2.16-.33.94-.65 1.74-.91 2.57-.13.44-.36.54-.78.48-.74-.11-1.49-.19-2.23-.28Z"
    />
    <Path
      fill="#e94e33"
      d="M49.71 9.07c0 .58-.02 1 0 1.41.02.39-.1.53-.51.47-.59-.09-1.18-.16-1.77-.19-.38-.02-.52-.16-.52-.53 0-.81-.02-1.62-.04-2.43-.04-1.8-.07-3.6-.12-5.4-.01-.38.09-.52.48-.56 1.16-.1 2.32-.24 3.48-.36.29-.03.59-.04.89-.03 1.87.03 2.9 1.37 2.44 3.19a4.42 4.42 0 0 1-1.72 2.56c-.46.34-.33.48.06.71.49.29.95.64 1.44.94.27.17.37.3.1.57-.42.42-.81.87-1.18 1.33-.23.29-.41.26-.68.05-.73-.56-1.48-1.09-2.36-1.73ZM4.93 11.71c-.45-.06-1.15.24-1.5-.12-.32-.33-.11-1.02-.13-1.54-.07-1.78-.14-3.55-.18-5.33 0-.39-.11-.51-.51-.45-.62.08-1.26.08-1.89.13-.31.03-.43-.09-.45-.38C.19 3.27.11 2.52 0 1.78c-.05-.37.11-.42.42-.44 2.6-.15 5.2-.32 7.81-.46C9.47.81 9.47.83 9.47 2.06q0 1.53-1.5 1.66-1.25.1-1.24 1.36c0 2.03-.01 4.07 0 6.1 0 .41-.11.57-.53.53-.39-.03-.79 0-1.28 0Z"
    />
    <Path
      fill="#3cbac6"
      d="M16.43 6.68c-.14 1.23-.58 2.32-1.39 3.28-1.25 1.48-3.9 1.89-5.47.84-1.45-.98-2.01-3.31-1.16-5.14.89-1.91 2.45-2.98 4.53-3.17.98-.09 1.86.3 2.57 1.02.11.36-.21.47-.39.65-.24.25-.27.42-.02.69.39.42.75.87 1.1 1.32.12.15.29.29.22.52Z"
    />
    <Path
      fill="#fdc40e"
      d="M16.43 6.68c-.48-.59-.94-1.2-1.45-1.75-.32-.34-.39-.58.03-.88.2-.14.41-.31.51-.55.34-.3.7-.59 1.01-.91.27-.29.42-.23.63.06.52.72 1.09 1.4 1.61 2.12.28.38.45.48.7-.02.42-.81.9-1.58 1.33-2.38.19-.35.35-.46.72-.21.52.35 1.08.65 1.63.97-.49.86-.72 1.77-.55 2.77-.37.58-.7 1.2-1.12 1.74-.45.58-.53 1.19-.42 1.89.08.56.1 1.14.16 1.71.04.34-.03.51-.43.48-.75-.06-1.5-.09-2.25-.09-.4 0-.49-.18-.51-.54-.02-.55-.1-1.1-.12-1.65-.03-.79-.2-1.51-.84-2.05-.24-.2-.43-.46-.64-.7ZM38.99 11.69s-.05-.07-.07-.11c-.19-.19-.81.02-.56-.59.4-.98.83-1.96 1.12-2.98.41-1.07.83-2.13 1.24-3.2.4-1.05.81-2.1 1.2-3.13.23-.03.24.15.3.25 1.5 3 2.99 6.01 4.5 9 .22.43.18.62-.3.75-.51.14-1.01.34-1.5.54-.32.13-.54.09-.64-.26-.25-.93-.83-1.27-1.8-1.31-1.05-.04-1.08-.14-1.47.82-.2.5-.45.6-.93.4-.34-.14-.71-.25-1.09-.19Z"
    />
    <Path
      fill="#5e4796"
      d="M56.24 9.08c0-.91-.01-1.82 0-2.73 0-.34-.04-.5-.44-.45-1.07.13-1.07.11-1.01-.96.02-.43.05-.87.03-1.3 0-.31.11-.41.4-.44 1.77-.14 3.54-.29 5.32-.44.29-.03.39.06.35.36-.09.67-.16 1.33-.23 2-.04.43-.4.36-.63.35-.65-.02-.81.25-.79.88.05 1.62-.02 3.24.03 4.86.02.57-.17.75-.7.78-.67.04-1.34.12-2 .25-.48.09-.53-.11-.52-.5.02-.89 0-1.78 0-2.67h.16Z"
    />
    <Path
      fill="#5e4797"
      d="M26.17 15.17c-.21-.27-.41-.54-.67-.86-.24.32-.46.61-.67.88-.06-.02-.11-.02-.13-.04q-.54-.95-1.57-.57c-.09.03-.17.1-.31 0 .19-.52.39-1.04.58-1.58.09-.24.26-.26.47-.24.97.07 1.93.15 2.9.2.59.03.46.55.62.86.08.16.26.39.09.54-.19.17-.36-.07-.51-.17-.24-.16-.32-.07-.42.16-.11.26-.13.58-.39.81Z"
    />
    <Path fill="#6dc1ad" d="M38.36 10.99c-.13.5.44.33.56.59-.31-.08-.88.09-.56-.59Z" />
    <Path
      fill="#fffcfb"
      d="M49.49 5.41c.05-1.03.19-1.2.94-1.19.54 0 .79.38.58.88-.13.3-.35.53-.62.7-.26.17-.57.46-.84.32-.23-.12.01-.51-.05-.71Z"
    />
    <Path
      fill="#feffff"
      d="M13.39 8.74c-.81-.63-1.54-.57-2.33 0 .27-.92.14-1.65-.74-2.2.96-.06 1.64-.41 1.89-1.43.33.93.87 1.46 1.91 1.37-.75.6-1.12 1.26-.73 2.25Z"
    />
    <Path
      fill="#fffefa"
      d="M42.25 5.51q.17.9 1.03.8c-.02.44-.74 2.36-1.12 2.93 0-.55-.03-1.03 0-1.51.04-.49-.13-.6-.56-.42-.07.03-.15.03-.33.06l.74-2.22c.32-.04.09.28.23.36Z"
    />
    <Path
      fill="#fef8d3"
      d="M42.25 5.51a7.22 7.22 0 0 1-.23-.36c0-.1-.03-.22.08-.26.08-.03.13.07.13.15.01.16.01.31.02.47Z"
    />
  </Svg>
)
export default ToySmartLogo
