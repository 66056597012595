import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

const AdidasLogo: React.FC<SvgProps> = (props) => (
  <Svg viewBox="0 0 23.99 16.15" {...props}>
    <Path d="m6.45 16.15-.15-.05c-.86-.25-1.41-.8-1.57-1.67-.16-.85.13-1.57.83-2.07a2.09 2.09 0 0 1 2.31-.13l.14.07v-1.68h1.01v5.44h-.99l-.01-.21-.84.31h-.72Zm1.56-2.1c0-.64-.54-1.17-1.18-1.17a1.2 1.2 0 0 0-1.18 1.17c0 .63.54 1.16 1.19 1.16.64 0 1.17-.53 1.17-1.16Zm4.67 2.1-.12-.04a2.05 2.05 0 0 1-1.58-1.68c-.16-.86.13-1.58.84-2.08a2.09 2.09 0 0 1 2.32-.11l.11.06v-1.68h.99v5.44h-.97l-.02-.21-.85.3h-.72Zm.4-.95c.64 0 1.18-.53 1.18-1.16a1.2 1.2 0 0 0-1.2-1.17c-.62 0-1.16.56-1.15 1.18 0 .63.54 1.15 1.17 1.15Zm4.28.95a2.02 2.02 0 0 1-.23-.07 2.06 2.06 0 0 1-1.5-1.68c-.15-.86.15-1.58.87-2.07.73-.5 1.5-.51 2.28-.1l.12.06v-.3h.99v4.06h-.99l-.02-.21-.82.31h-.72Zm.38-3.28c-.64 0-1.17.54-1.17 1.18 0 .62.53 1.14 1.17 1.15.64 0 1.17-.53 1.17-1.16 0-.64-.53-1.17-1.16-1.17ZM0 13.78l.07-.29a2.14 2.14 0 0 1 3.1-1.28l.16.09V12h.99v4.06h-.98l-.01-.21-.84.3h-.72l-.17-.05a2.13 2.13 0 0 1-1.53-1.5L0 14.3v-.51Zm3.33.26a1.2 1.2 0 0 0-1.18-1.17 1.2 1.2 0 0 0-1.18 1.17c0 .63.54 1.16 1.18 1.15.65 0 1.18-.53 1.18-1.16Zm18.41 2.11-.35-.09c-.63-.19-1.03-.65-1.07-1.23v-.06h1.04c.15.55.76.81 1.27.54a.5.5 0 0 0 .16-.13c.17-.19.13-.43-.11-.52-.18-.07-.37-.09-.55-.13-.32-.07-.64-.12-.95-.21-.88-.27-1.02-1.4-.36-1.95.14-.12.3-.22.47-.28.54-.2 1.09-.2 1.63 0 .53.2.84.59.91 1.17h-1l-.02-.03c-.02-.33-.2-.5-.6-.51-.17 0-.34.02-.5.05a.31.31 0 0 0-.26.3c-.01.16.1.26.23.29.28.08.57.13.86.2.26.06.53.11.77.22.81.37.92 1.45.21 2a1.8 1.8 0 0 1-.77.32c-.05 0-.1.02-.14.04h-.87Zm-1.3-5.55h-4.6c-.1 0-.16-.03-.21-.12l-4.8-8.35-.05-.11 3.54-2.03 6.13 10.6ZM6.72 5.76l3.54-2.04 3.97 6.88h-3.71v.99H9.51v-.85c0-.1-.03-.21-.08-.29L6.8 5.91l-.09-.16Zm1.26 4.84H3.41c-.12 0-.19-.04-.24-.14l-.6-1.06 3.54-2.05 1.88 3.25Zm2.54 1.4v4.05h-.99V12h.99Z" />
  </Svg>
)

export default AdidasLogo
