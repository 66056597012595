import React from 'react'
import {
  Button,
  type SizeTokens,
  Spinner,
  type TamaguiElement,
  type ThemeValueFallback,
  YStack,
  getToken,
  useWindowDimensions,
} from 'tamagui'
import { ContentBoldText, HighlightBoldText } from '@centrito/ui/src/components/text/new'

export interface PrimaryButtonProps {
  onPress?: () => void
  width?: SizeTokens | ThemeValueFallback
  isDisabled?: boolean
  isLoading?: boolean
  backgroundColor?: string
  iconBefore?: JSX.Element
  iconAfter?: JSX.Element
  flex?: number
  isSizableText?: boolean
  children: string
}

// eslint-disable-next-line react/display-name
export const PrimaryButton = React.forwardRef<TamaguiElement, PrimaryButtonProps>(
  (
    {
      children,
      onPress,
      width,
      isDisabled = false,
      iconAfter = undefined,
      iconBefore = undefined,
      isLoading = false,
      isSizableText = false,
      backgroundColor = '$primary1000',
      flex = undefined,
    },
    ref,
  ) => {
    const { width: _width } = useWindowDimensions()
    return (
      <YStack width={width} flex={flex}>
        <Button
          justifyContent="center"
          backgroundColor={backgroundColor}
          color={getToken('$baseWhite')}
          onPress={onPress}
          pressStyle={{
            backgroundColor: '$primary700',
            outlineStyle: 'none',
          }}
          hoverStyle={{
            backgroundColor: '$primary800',
            outlineStyle: 'none',
          }}
          disabledStyle={{
            backgroundColor: '$neutral300',
            outlineStyle: 'none',
          }}
          disabled={isDisabled}
          iconAfter={isLoading ? <Spinner /> : iconAfter}
          icon={iconBefore}
          ref={ref}
          alignItems="center"
          size={48}
        >
          {_width < 400 && isSizableText ? (
            <ContentBoldText
              color={isDisabled ? '$neutral500' : getToken('$baseWhite')}
              userSelect="none"
            >
              {children}
            </ContentBoldText>
          ) : (
            <HighlightBoldText
              color={isDisabled ? '$neutral500' : getToken('$baseWhite')}
              userSelect="none"
            >
              {children}
            </HighlightBoldText>
          )}
        </Button>
      </YStack>
    )
  },
)
